import React, { Component } from "react"
import { fields } from "../../../../fields"

import { Container, Form, FormField, Input, TextArea } from 'semantic-ui-react'

const departmentName = 'Business Development'

class BusinessDev extends Component {
    state = {}

    componentDidMount() {
        var savedDict = {}
        if (this.props.savedDict) {
            savedDict = this.props.savedDict
        } else {
            return
        }
        this.setState({
            creditLimit: savedDict.creditLimit,
            quotationRemarks: savedDict.quotationRemarks,
            feedbackReceived: savedDict.feedbackReceived,
            remarks: savedDict.remarks,
            others: savedDict.others
        })
    }

    handleChangeField = e => {
        if (this.props.viewOnly) {
            if (this.state[e.target.name] == null) {
                this.setState({ [e.target.name]: ""})
            }
            return
        }
        const { name, value } = e.target
        if (!getWritePermissionForField(name, this.props.permissions) && this.state[e.target.name] == null) {
            this.setState({ [e.target.name]: ""})
            return
        }   
        else if (!getWritePermissionForField(name, this.props.permissions)) return
        this.setState({ [name]: value }, () =>
            this.props.onStateChange(this.state)
        )
    }

    getFieldStyle(name) {
        if (!getReadPermissionForField(name, this.props.permissions)) {
            return { visibility: 'hidden' }
        }
        return { visibility: 'visible' }
    }
    
    render() {
        const { creditLimit, quotationRemarks, feedbackReceived, remarks, others } = this.state
        return (
            <Container>
                <Form>
                    <FormField style={this.getFieldStyle('creditLimit')} control={Input} label='Credit Limit' name='creditLimit' value={creditLimit} placeholder='Credit Limit' onChange={this.handleChangeField}/>
                    <FormField style={this.getFieldStyle('quotationRemarks')} control={TextArea} rows='5' label='Quotation Remarks' name='quotationRemarks' value={quotationRemarks} placeholder='' onChange={this.handleChangeField}/>
                    <FormField style={this.getFieldStyle('feedbackReceived')} control={TextArea} rows='5' label='Feedback Received' name='feedbackReceived' value={feedbackReceived} placeholder='' onChange={this.handleChangeField}/>
                    <FormField style={this.getFieldStyle('remarks')} control={TextArea} rows='2' label='Remarks' name='remarks' value={remarks} placeholder='' onChange={this.handleChangeField}/>
                    <FormField style={this.getFieldStyle('others')} control={Input} label='Others' name='others' value={others} placeholder='' onChange={this.handleChangeField}/>
                </Form>
            </Container>
        )
    }
}

function getWritePermissionForField(fieldName, permissions) {
    var key = ""
    for (let field of fields) {
        if (field.field === fieldName && field.departmentName === departmentName) {
            key = field.key
        }
    }
    for (let permission of permissions) {
        if (permission.key === key) {
            if (permission.write === false) {
            }
            return permission.write
        }
    }
}

function getReadPermissionForField(fieldName, permissions) {
    var key = ""
    for (let field of fields) {
        if (field.field === fieldName && field.departmentName === departmentName) {
            key = field.key
        }
    }
    for (let permission of permissions) {
        if (permission.key === key) {
            return permission.read
        }
    }
}

export default BusinessDev