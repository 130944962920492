import React, { Component } from "react"
import { connect } from "react-redux"
import * as actions from "../../../actions"

import ClientInfo from "./client-forms/ClientInfo"
import Audit from "./client-forms/Audit"
import BusinessDev from "./client-forms/BusinessDev"
import Operations from "./client-forms/Operations"
import CorpSec from "./client-forms/CorpSec"
import OutsourcedAccounting from "./client-forms/OutsourcedAccounting"
import TaxFS from "./client-forms/TaxFS"
import AuditTrail from "./AuditTrail"

import { Container, Breadcrumb, BreadcrumbSection, BreadcrumbDivider, Menu, MenuItem, MenuMenu, Icon, Message, Button, ButtonContent } from 'semantic-ui-react'

import { Packer } from "docx"
import { saveAs } from "file-saver"
import { doc } from '../../../ClientInformationDocument'

const downloadButtonStyle = {
    width: "100px",
}

class ViewClient extends Component {
    state = { activeItem: '' }
  
    constructor(props) {
        super(props)
        this.handleMenuItemClicked = this.handleMenuItemClicked.bind(this)
        this.handleDownloadClicked = this.handleDownloadClicked.bind(this)
    }

    componentDidMount() {
        var showClientInfo, showAudit, showBusinessDev, showOperations, showCorpSec, showAccounting, showTax, showAuditTrail = false
        if (!!this.props.user) {
            if (!!this.props.user.role) {
                if (!!this.props.user.name) {
                    if (this.props.user.role.name.toLowerCase().includes("superadmin")) {
                        showAuditTrail = true
                    } 
                }
            }
        }
        if (this.props == null) {
            return
        }
        for (let permission of this.props.permissions) {
            const dept = permission.key.split('-')[1]
            if (dept === 'ct' && permission.read === true) {
                showClientInfo = true
                continue
            } else if (dept === 'au' && permission.read === true) {
                showAudit = true
                continue
            } else if (dept === 'bd' && permission.read === true) {
                showBusinessDev = true
                continue
            } else if (dept === 'op' && permission.read === true) {
                showOperations = true
                continue
            } else if (dept === 'cs' && permission.read === true) {
                showCorpSec = true
                continue
            } else if (dept === 'oa' && permission.read === true) {
                showAccounting = true
                continue
            } else if (dept === 'tx' && permission.read === true) {
                showTax = true
                continue
            }
        }
        this.setState({ showClientInfo, showAudit, showBusinessDev, showOperations, showCorpSec, showAccounting, showTax, showAuditTrail })
        if (showClientInfo) {
            this.setState( { activeItem: 'info' })
        } else if (showAudit) {
            this.setState( { activeItem: 'audit' })
        }
    }
    
    renderContent() {
        let perms = this.props.permissions
        switch (this.state.activeItem) {
            case "info":
                return <ClientInfo viewOnly savedDict={this.props.clientToView.clientInfo} permissions={getPermissionsForDept('ct', perms)}/>
            case "audit":
                return <Audit viewOnly savedDict={this.props.clientToView.audit} permissions={getPermissionsForDept('au', perms)}/>
            case "businessDev":
                return <BusinessDev viewOnly savedDict={this.props.clientToView.businessDev} permissions={getPermissionsForDept('bd', perms)}/>
            case "operations":
                return <Operations viewOnly savedDict={this.props.clientToView.operations} permissions={getPermissionsForDept('op', perms)}/>
            case "corpSec":
                return <CorpSec viewOnly savedDict={this.props.clientToView.corpSec} permissions={getPermissionsForDept('cs', perms)}/>
            case "outsourcedAccounting":
                return <OutsourcedAccounting viewOnly savedDict={this.props.clientToView.outsourcedAccounting} permissions={getPermissionsForDept('oa', perms)}/>
            case "taxFS":
                return <TaxFS viewOnly savedDict={this.props.clientToView.taxFS} permissions={getPermissionsForDept('tx', perms)}/>
            case "auditTrail":
                return <AuditTrail auditTrail={this.props.clientToView.auditTrail}/>
            default:
                return
        }
    }

    renderClientInfo() {
        if (this.state.showClientInfo) return <MenuItem name='Client Info' active={this.state.activeItem === 'info'} onClick={this.handleMenuItemClicked}/>
    }

    renderAudit() {
        if (this.state.showAudit) return <MenuItem name='Audit' active={this.state.activeItem === 'audit'} onClick={this.handleMenuItemClicked}/>
    }

    renderBusinessDev() {
        if (this.state.showBusinessDev) return <MenuItem name='Business Development' active={this.state.activeItem === 'businessDev'} onClick={this.handleMenuItemClicked}/>
    }

    renderOperations() {
        if (this.state.showOperations) return <MenuItem name='Operations' active={this.state.activeItem === 'operations'} onClick={this.handleMenuItemClicked}/>
    }

    renderCorpSec() {
        if (this.state.showCorpSec) return <MenuItem name='Corp Sec' active={this.state.activeItem === 'corpSec'} onClick={this.handleMenuItemClicked}/>
    }

    renderAccounting() {
        if (this.state.showAccounting) return <MenuItem name='Outsourced Accounting' active={this.state.activeItem === 'outsourcedAccounting'} onClick={this.handleMenuItemClicked}/>
    }

    renderTax() {
        if (this.state.showTax) return <MenuItem name='Tax And FS' active={this.state.activeItem === 'taxFS'} onClick={this.handleMenuItemClicked}/>
    }

    renderAuditTrail() {
        if (this.state.showAuditTrail) return(<MenuItem name='Audit Trail' active={this.state.activeItem === 'auditTrail'} onClick={this.handleMenuItemClicked}/>)
    }

    renderDownload() {
        if (!this.props.canExportClientInfoForm) return
        return (
            <MenuMenu position='right'>
            <MenuItem>
            <Button style={downloadButtonStyle} animated='fade' color='red' onClick={this.handleDownloadClicked}>
                <ButtonContent hidden>Download</ButtonContent>
                <ButtonContent visible>
                    <Icon name='download'/>
                </ButtonContent>
            </Button>
            </MenuItem>
          </MenuMenu>
        )
    }

    render() {
        return (
            <Container>
                <br />
               <Breadcrumb size='large'>
                    <BreadcrumbSection link onClick={this.props.onBackClicked}>My Clients</BreadcrumbSection>
                    <BreadcrumbDivider icon='right chevron'/>
                    <BreadcrumbSection active>{this.props.clientToView.clientInfo.companyName}</BreadcrumbSection>
                </Breadcrumb>
                <br />
                <br />
                <Menu tabular>
                    {this.renderClientInfo()}
                    {this.renderAudit()}
                    {this.renderBusinessDev()}
                    {this.renderOperations()}
                    {this.renderCorpSec()}
                    {this.renderAccounting()}
                    {this.renderTax()}
                    {this.renderAuditTrail()}
                    {this.renderDownload()}
                </Menu>
                <Message info header='This page is view-only.' content='Its contents are uneditable.'/>
                {this.renderContent()}
                <br /><br />
                <Button onClick={this.props.onBackClicked}>Back to Clients Page</Button>
                <br /><br /><br /><br /><br /><br /><br /><br />
            </Container>
        )
    }

    handleDownloadClicked() {
        var dict = this.props.clientToView.clientInfo
        if (dict.companyName == null) {
            dict.companyName = ''
        }
        if (dict.roc == null) dict.roc = ''        
        if (dict.mailingAddress == null) dict.mailingAddress = ''
        if (dict.registeredAddress == null) dict.registeredAddress = ''
        if (dict.roc == null) dict.roc = ''
        if (dict.phone1 == null) dict.phone1 = ''
        if (dict.phone2 == null) dict.phone2 = ''
        if (dict.fax == null) dict.fax = ''
        
        let string = `Client Information Update – ${dict.companyName}.docx`
        Packer.toBlob(doc(dict)).then((blob) => {
            saveAs(blob, string)
        })
        this.props.userAuditTrailDownloadClientInfoForm(this.props.user._id, { companyName: this.props.clientToView.clientInfo.companyName }, (success) => {})

    }

    handleMenuItemClicked(e) {
        switch (e.target.innerText) {
            case "Client Info":
                this.setState({activeItem: "info"})
                break
            case "Audit":
                this.setState({activeItem: "audit"})
                break
            case "Business Development":
                this.setState({activeItem: "businessDev"})
                break
            case "Operations":
                this.setState({activeItem: "operations"})
                break
            case "Corp Sec":
                this.setState({activeItem: "corpSec"})
                break
            case "Outsourced Accounting":
                this.setState({activeItem: "outsourcedAccounting"})
                break
            case "Tax And FS":
                this.setState({activeItem: "taxFS"})
                break
            case "Audit Trail":
                this.setState({activeItem: "auditTrail"})
                break
            default:
                break
        }
    }
}

function getPermissionsForDept(dept, permissions) {
    if (permissions == null) {
        return
    }
    var arr = []
    for (let permission of permissions) {
        const dep = permission.key.split('-')[1]
        if (dep === dept) {
            arr.push(permission)
        }
    }
    return arr
}

function mapStateToProps({ user }) {
    return { user }
}

export default connect(mapStateToProps, actions)(ViewClient)