import { React, Component } from 'react'
import { connect } from "react-redux"
import * as actions from "../actions"

import { Container, Image, FormField, Button, Form, MessageHeader, Message } from 'semantic-ui-react'

class Landing extends Component {
    
    state = { email: "", password: "", formSubmitting: false, formIncompleteError: false, invalidLogin: false }

    componentWillReceiveProps(props) {
        console.log(props.auth)
        if (props.auth != null) {
            this.setState({
                invalidLogin: props.auth.invalidLogin
            })
            if (!props.auth.invalidLogin) {
                this.props.history.replace("/2fa")
            }
        }
    }
    
    handleChange = e => {
        const { name, value } = e.target
        this.setState({ [name]: value })
        const { email, password } = this.state
        if (email !== "" && password !== "" && this.state.formIncompleteError) {
            this.setState({ formIncompleteError: false })
        } 
    }

    handleSubmitClicked = () => {
        const { email, password } = this.state
        if (email === "" || password === "") {
            this.setState({ formIncompleteError: true })
            return
        }
        this.setState({ formSubmitting: true }, async function() {
            this.props.login(email.toLowerCase(), password, "", () => {
                this.setState({ formSubmitting: false })
            })
        })
    }

    renderLoginFailedMessage() {
        if (this.state.invalidLogin) {
            return (
                <Message negative>
                    <MessageHeader>Login failed</MessageHeader>
                    <p>Please check your email or password</p>
                </Message>
            )
        } else {
            return
        }
    }

    renderFormIncompleteMessage() {
        if (this.state.formIncompleteError) {
            return (
                <Message negative>
                    <MessageHeader>Please fill in email and password</MessageHeader>
                </Message>
            )
        } else {
            return 
        }
    }
    
    render() {
        return (
            <div>
            <br />
            <br />
            <Image size='large' centered src='/bubbly-logo.png'/>
            <br />
            <Container text>
                <Form loading={this.state.formSubmitting}>
                    <FormField>
                        <label>Email</label>
                        <input name='email' value={this.state.email} onChange={this.handleChange} placeholder='Email' />
                    </FormField>
                    <FormField>
                        <label>Password</label>
                        <input name='password' value={this.state.password} type="password" onChange={this.handleChange} placeholder='Password' />
                    </FormField>
                    {this.renderLoginFailedMessage()}
                    {this.renderFormIncompleteMessage()}
                    <br />
                    <Button fluid color='black' type='submit' onClick={this.handleSubmitClicked}>Login</Button>
                </Form>
            </Container>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { auth: state.auth }
}

export default connect(mapStateToProps, actions)(Landing)