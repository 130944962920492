
export const departments = [
    {
        key: 'dept-1',
        dept: 'clientInfo',
        deptName: 'Client Info'
    },
    {
        key: 'dept-1',
        dept: 'audit',
        deptName: 'Audit'
    },
    {
        key: 'dept-3',
        dept: 'businessDev',
        deptName: 'Business Development'
    },
    {
        key: 'dept-4',
        dept: 'operations',
        deptName: 'Operations'
    },
    {
        key: 'dept-5',
        dept: 'corpSec',
        deptName: 'Corp Sec'
    },
    {
        key: 'dept-6',
        dept: 'outsourcedAccounting',
        deptName: 'Outsourced Accounting'
    },
    {
        key: 'dept-7',
        dept: 'taxFS',
        deptName: 'Tax And FS'
    },
]

export const fields = [
    {
        key: 'field-ct-1',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'clientType',
        fieldName: 'Client Type'
    },
    {
        key: 'field-ct-2',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'companyName',
        fieldName: 'Company Name'
    },
    {
        key: 'field-ct-3',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'roc',
        fieldName: 'ROC'
    },
    {
        key: 'field-ct-4',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'entityType',
        fieldName: 'Entity Type'
    },
    {
        key: 'field-ct-5',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'suspendPayment',
        fieldName: 'Suspend Payment'
    },
    {
        key: 'field-ct-6',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'oneContactPoint',
        fieldName: 'One Contact Point'
    },
    {
        key: 'field-ct-7',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'group',
        fieldName: 'Group'
    },
    {
        key: 'field-ct-8',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'remarksInfo',
        fieldName: 'Remarks (Client Information)'
    },
    {
        key: 'field-ct-9',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'othersInfo',
        fieldName: 'Others (Client Information)'
    },
    {
        key: 'field-ct-10',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'registeredAddress',
        fieldName: 'Registered Address'
    },
    {
        key: 'field-ct-11',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'mailingAddress',
        fieldName: 'Mailing Address'
    },
    {
        key: 'field-ct-12',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'phone1',
        fieldName: 'Phone 1'
    },
    {
        key: 'field-ct-13',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'phone2',
        fieldName: 'Phone 2'
    },
    {
        key: 'field-ct-14',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'fax',
        fieldName: 'Fax'
    },
    {
        key: 'field-ct-15',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'remarksContact',
        fieldName: 'Remarks (Client Contact Details)'
    },
    {
        key: 'field-ct-16',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'othersContact',
        fieldName: 'Others (Client Contact Details)'
    },
    {
        key: 'field-ct-17',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'auth1Name',
        fieldName: 'Authorised Person 1 Name'
    },
    {
        key: 'field-ct-18',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'auth1Position',
        fieldName: 'Authorised Person 1 Position'
    },
    {
        key: 'field-ct-19',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'auth1Mobile',
        fieldName: 'Authorised Person 1 Mobile Number'
    },
    {
        key: 'field-ct-20',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'auth1Email',
        fieldName: 'Authorised Person 1 Email Address'
    },
    {
        key: 'field-ct-21',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'auth1OptOutEmail',
        fieldName: 'Authorised Person 1 Opt-out Email'
    },
    {
        key: 'field-ct-22',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'auth1Remarks',
        fieldName: 'Authorised Person 1 Remarks'
    },
    {
        key: 'field-ct-23',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'auth1Others',
        fieldName: 'Authorised Person 1 Others'
    },
    {
        key: 'field-ct-24',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'auth2Name',
        fieldName: 'Authorised Person 2 Name'
    },
    {
        key: 'field-ct-25',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'auth2Position',
        fieldName: 'Authorised Person 2 Position'
    },
    {
        key: 'field-ct-26',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'auth2Mobile',
        fieldName: 'Authorised Person 2 Mobile Number'
    },
    {
        key: 'field-ct-27',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'auth2Email',
        fieldName: 'Authorised Person 2 Email Address'
    },
    {
        key: 'field-ct-28',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'auth2OptOutEmail',
        fieldName: 'Authorised Person 2 Opt-out Email'
    },
    {
        key: 'field-ct-29',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'auth2Remarks',
        fieldName: 'Authorised Person 2 Remarks'
    },
    {
        key: 'field-ct-30',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'auth2Others',
        fieldName: 'Authorised Person 2 Others'
    },
    {
        key: 'field-ct-31',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'auth3Name',
        fieldName: 'Authorised Person 3 Name'
    },
    {
        key: 'field-ct-32',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'auth3Position',
        fieldName: 'Authorised Person 3 Position'
    },
    {
        key: 'field-ct-33',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'auth3Mobile',
        fieldName: 'Authorised Person 3 Mobile Number'
    },
    {
        key: 'field-ct-34',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'auth3Email',
        fieldName: 'Authorised Person 3 Email Address'
    },
    {
        key: 'field-ct-35',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'auth3OptOutEmail',
        fieldName: 'Authorised Person 3 Opt-out Email'
    },
    {
        key: 'field-ct-36',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'auth3Remarks',
        fieldName: 'Authorised Person 3 Remarks'
    },
    {
        key: 'field-ct-37',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'auth3Others',
        fieldName: 'Authorised Person 3 Others'
    },
    {
        key: 'field-ct-38',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'auth4Name',
        fieldName: 'Authorised Person 4 Name'
    },
    {
        key: 'field-ct-39',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'auth4Position',
        fieldName: 'Authorised Person 4 Position'
    },
    {
        key: 'field-ct-40',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'auth4Mobile',
        fieldName: 'Authorised Person 4 Mobile Number'
    },
    {
        key: 'field-ct-41',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'auth4Email',
        fieldName: 'Authorised Person 4 Email Address'
    },
    {
        key: 'field-ct-42',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'auth4OptOutEmail',
        fieldName: 'Authorised Person 4 Opt-out Email'
    },
    {
        key: 'field-ct-43',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'auth4Remarks',
        fieldName: 'Authorised Person 4 Remarks'
    },
    {
        key: 'field-ct-44',
        department: 'clientInfo',
        departmentName: 'Client Info',
        field: 'auth4Others',
        fieldName: 'Authorised Person 4 Others'
    },

    
    {
        key: 'field-au-1',
        department: 'audit',
        departmentName: 'Audit',
        field: 'stocktakeRequired',
        fieldName: 'Stock Take Required'
    },
    {
        key: 'field-au-2',
        department: 'audit',
        departmentName: 'Audit',
        field: 'auditAppointmentDate',
        fieldName: 'Date of Audit Appointment'
    },
    {
        key: 'field-au-3',
        department: 'audit',
        departmentName: 'Audit',
        field: 'consentLetterDate',
        fieldName: 'Date of Consent Letter'
    },
    {
        key: 'field-au-4',
        department: 'audit',
        departmentName: 'Audit',
        field: 'auditResignationLetterDate',
        fieldName: 'Date of Audit Resignation Letter'
    },
    {
        key: 'field-au-5',
        department: 'audit',
        departmentName: 'Audit',
        field: 'lastAccountAuditedDate',
        fieldName: 'Date of Last Account Audited'
    },
    {
        key: 'field-au-6',
        department: 'audit',
        departmentName: 'Audit',
        field: 'bankConfirmationStatus',
        fieldName: 'Status of Bank Confirmation'
    },
    {
        key: 'field-au-7',
        department: 'audit',
        departmentName: 'Audit',
        field: 'remarks',
        fieldName: 'Remarks'
    },
    {
        key: 'field-au-8',
        department: 'audit',
        departmentName: 'Audit',
        field: 'others',
        fieldName: 'Others'
    },


    {
        key: 'field-bd-1',
        department: 'businessDev',
        departmentName: 'Business Development',
        field: 'creditLimit',
        fieldName: 'Credit Limit'
    },
    {
        key: 'field-bd-2',
        department: 'businessDev',
        departmentName: 'Business Development',
        field: 'quotationRemarks',
        fieldName: 'Quotation Remarks'
    },
    {
        key: 'field-bd-3',
        department: 'businessDev',
        departmentName: 'Business Development',
        field: 'feedbackReceived',
        fieldName: 'Feedback Received'
    },
    {
        key: 'field-bd-4',
        department: 'businessDev',
        departmentName: 'Business Development',
        field: 'remarks',
        fieldName: 'Remarks'
    },
    {
        key: 'field-bd-5',
        department: 'businessDev',
        departmentName: 'Business Development',
        field: 'others',
        fieldName: 'Others'
    },


    {
        key: 'field-op-1',
        department: 'operations',
        departmentName: 'Operations',
        field: 'awpDrawerNo',
        fieldName: 'AWP Drawer No'
    },
    {
        key: 'field-op-2',
        department: 'operations',
        departmentName: 'Operations',
        field: 'ctDrawerNo',
        fieldName: 'C&T Drawer No'
    },
    {
        key: 'field-op-3',
        department: 'operations',
        departmentName: 'Operations',
        field: 'extCorpSecName',
        fieldName: 'Name of External Corp Sec'
    },
    {
        key: 'field-op-4',
        department: 'operations',
        departmentName: 'Operations',
        field: 'extCorpSecEmail',
        fieldName: 'Email of External Corp Sec'
    },
    {
        key: 'field-op-5',
        department: 'operations',
        departmentName: 'Operations',
        field: 'extCorpSecAddress',
        fieldName: 'Address of External Corp Sec'
    },
    {
        key: 'field-op-6',
        department: 'operations',
        departmentName: 'Operations',
        field: 'extSecGroup',
        fieldName: 'Group (External Sec)'
    },
    {
        key: 'field-op-7',
        department: 'operations',
        departmentName: 'Operations',
        field: 'extSecYearEnd',
        fieldName: 'Year End (External Sec)'
    },
    {
        key: 'field-op-8',
        department: 'operations',
        departmentName: 'Operations',
        field: 'dateOfAppointment',
        fieldName: 'Date of Appointment'
    },
    {
        key: 'field-op-9',
        department: 'operations',
        departmentName: 'Operations',
        field: 'reportToTourismBoard',
        fieldName: 'Report to Tourism Board?'
    },
    {
        key: 'field-op-10',
        department: 'operations',
        departmentName: 'Operations',
        field: 'remarks',
        fieldName: 'Remarks'
    },
    {
        key: 'field-op-11',
        department: 'operations',
        departmentName: 'Operations',
        field: 'others',
        fieldName: 'Others'
    },
    

    {
        key: 'field-cs-1',
        department: 'corpSec',
        departmentName: 'Corp Sec',
        field: 'adHoc',
        fieldName: 'Ad Hoc'
    },
    {
        key: 'field-cs-2',
        department: 'corpSec',
        departmentName: 'Corp Sec',
        field: 'customerNo',
        fieldName: 'Customer No'
    },
    {
        key: 'field-cs-3',
        department: 'corpSec',
        departmentName: 'Corp Sec',
        field: 'group',
        fieldName: 'Group'
    },
    {
        key: 'field-cs-4',
        department: 'corpSec',
        departmentName: 'Corp Sec',
        field: 'dateOfIncorp',
        fieldName: 'Date of Incorporation'
    },
    {
        key: 'field-cs-5',
        department: 'corpSec',
        departmentName: 'Corp Sec',
        field: 'dateAcraStrikeOff',
        fieldName: 'Date ACRA Filing for Strike Off'
    },
    {
        key: 'field-cs-6',
        department: 'corpSec',
        departmentName: 'Corp Sec',
        field: 'noStrikeOffDate',
        fieldName: 'No Strike Off Date'
    },
    {
        key: 'field-cs-7',
        department: 'corpSec',
        departmentName: 'Corp Sec',
        field: 'FYE',
        fieldName: 'FYE'
    },
    {
        key: 'field-cs-8',
        department: 'corpSec',
        departmentName: 'Corp Sec',
        field: 'lastAccountFiled',
        fieldName: 'Last Account Filed'
    },
    {
        key: 'field-cs-9',
        department: 'corpSec',
        departmentName: 'Corp Sec',
        field: 'agreementNo',
        fieldName: 'Agreement No'
    },
    {
        key: 'field-cs-10',
        department: 'corpSec',
        departmentName: 'Corp Sec',
        field: 'dateOfAgreement',
        fieldName: 'Date of Agreement'
    },
    {
        key: 'field-cs-11',
        department: 'corpSec',
        departmentName: 'Corp Sec',
        field: 'monthOfAgreement',
        fieldName: 'Month of Agreement'
    },
    {
        key: 'field-cs-12',
        department: 'corpSec',
        departmentName: 'Corp Sec',
        field: 'remarksForMonthOfAgreement',
        fieldName: 'Remarks for Month of Agreement'
    },
    {
        key: 'field-cs-13',
        department: 'corpSec',
        departmentName: 'Corp Sec',
        field: 'commonSeal',
        fieldName: 'Common Seal'
    },
    {
        key: 'field-cs-14',
        department: 'corpSec',
        departmentName: 'Corp Sec',
        field: 'nameOfSecretary',
        fieldName: 'Name of Secretary (1)'
    },
    {
        key: 'field-cs-15',
        department: 'corpSec',
        departmentName: 'Corp Sec',
        field: 'dateApptAsSec',
        fieldName: 'Date of Appointment as Secretary (1)'
    },
    {
        key: 'field-cs-16',
        department: 'corpSec',
        departmentName: 'Corp Sec',
        field: 'dateResignAsSec',
        fieldName: 'Date of Resignation as Secretary (1)'
    },
    {
        key: 'field-cs-17',
        department: 'corpSec',
        departmentName: 'Corp Sec',
        field: 'nameOfSecretary2',
        fieldName: 'Name of Secretary (2)'
    },
    {
        key: 'field-cs-18',
        department: 'corpSec',
        departmentName: 'Corp Sec',
        field: 'dateApptAsSec2',
        fieldName: 'Date of Appointment as Secretary (2)'
    },
    {
        key: 'field-cs-19',
        department: 'corpSec',
        departmentName: 'Corp Sec',
        field: 'dateResignAsSec2',
        fieldName: 'Date of Resignation as Secretary (2)'
    },
    {
        key: 'field-cs-20',
        department: 'corpSec',
        departmentName: 'Corp Sec',
        field: 'remarksForApptResignAsSec',
        fieldName: 'Remarks for Appointment / Resignation as Secretary'
    },
    {
        key: 'field-cs-21',
        department: 'corpSec',
        departmentName: 'Corp Sec',
        field: 'howManyShareholderAgreement',
        fieldName: 'How many shareholder agreeement'
    },
    {
        key: 'field-cs-22',
        department: 'corpSec',
        departmentName: 'Corp Sec',
        field: 'remarks',
        fieldName: 'Remarks'
    },
    {
        key: 'field-cs-23',
        department: 'corpSec',
        departmentName: 'Corp Sec',
        field: 'others',
        fieldName: 'Others'
    },


    {
        key: 'field-oa-1',
        department: 'outsourcedAccounting',
        departmentName: 'Outsourced Accounting',
        field: 'typeOfService',
        fieldName: 'Type of Service'
    },
    {
        key: 'field-oa-2',
        department: 'outsourcedAccounting',
        departmentName: 'Outsourced Accounting',
        field: 'dateOfUpdate',
        fieldName: 'Date of Update'
    },
    {
        key: 'field-oa-3',
        department: 'outsourcedAccounting',
        departmentName: 'Outsourced Accounting',
        field: 'principalStaff',
        fieldName: 'Principal Staff'
    },
    {
        key: 'field-oa-4',
        department: 'outsourcedAccounting',
        departmentName: 'Outsourced Accounting',
        field: 'remarks',
        fieldName: 'Remarks'
    },


    {
        key: 'field-tx-1',
        department: 'taxFS',
        departmentName: 'Tax And FS',
        field: 'remarks',
        fieldName: 'Remarks'
    },
]
/*
// what will appear in user.permissions: 
permissions: [
    {   
        key: 'ct-001',
        read: false,
        write: false
    }
]
*/