import axios from 'axios'
import { FETCH_USER, LOGIN, LOGOUT, CREATE_CLIENT, FETCH_CLIENTS, EDIT_CLIENT, DELETE_CLIENT, CREATE_USER, FETCH_USERS, EDIT_USER, DELETE_USER, CREATE_ROLE, FETCH_ROLES, EDIT_ROLE, DELETE_ROLE, TWOFA } from './types'

export const login = (email, password, code, callback) => dispatch => {
    axios({ method: 'post', url: '/api/login', data: { username: email, password: password, code } }) // passport.js requires it to be 'username'.
    .then((res) => { 
        if (res.status === 202) {
            console.log("login success but user needs to set up 2FA")
            console.log(res.data)
            console.log(res.data.user)
            dispatch({ type: LOGIN, payload: { invalidLogin: false, twoFASetupRequired: true, qr: res.data.data.qr, secret: res.data.data.secret, user: res.data.user }})
            callback()
            return
        }
        console.log("login success")
        console.log(res.data)
        dispatch({ type: LOGIN, payload: { invalidLogin: false, twoFASetupRequired: false, user: res.data.user }})
        callback()
    })
    .catch((err) => {
        console.log("login failed")
        console.log(err)
        dispatch({ type: LOGIN, payload: { invalidLogin: true } })
        callback()
    })
}

export const twoFA = (user, secret, code, callback) => dispatch => {
    axios({ method: 'post', url: '/api/verify-totp', data: { user, secret, code } })
    .then((res) => {
        console.log('2FA success')
        dispatch({ type: TWOFA, payload: { invalidCode: false, verified2FA: true }})
        callback()
    })
    .catch((err) => {
        console.log('2FA failed')
        console.log(err)
        dispatch({ type: TWOFA, payload: { invalidCode: true, verified2FA: false } })
        callback()
    })
}

export const logout = () => async dispatch => {
    await axios.post('/api/logout')
    dispatch({ type: LOGOUT, payload: {} })
    window.location.href = "/"
}


export const fetchUser = () => async dispatch => {
    const res = await axios.get('/api/current_user')
    dispatch({ type: FETCH_USER, payload: res.data })
} 


export const createClient = (body, callback) => dispatch => {
    axios.post('/api/client/create', body)
    .then((res) => {
        console.log("createClient success")
        dispatch({ type: CREATE_CLIENT, payload: { createClientFailure: false } })
        callback(true)
    })
    .catch((err) => {
        console.log(`createClient failed with error: ${err}`)
        dispatch({ type: CREATE_CLIENT, payload: { createClientFailure: true } })
        callback(false)
    })
}

export const addAuditDataToAllClients = (body, callback) => dispatch => {
    axios.post('/api/clients/add_audit_data', body)
    .then((res) => {
        console.log("addAuditDataToAllClients success")
        dispatch({ type: CREATE_CLIENT, payload: { createClientFailure: false } })
        callback(res.status)
    })
    .catch((err) => {
        console.log(`addAuditDataToAllClients failed with error: ${err}`)
        dispatch({ type: CREATE_CLIENT, payload: { createClientFailure: true } })
        callback(false)
    })
}

export const fetchClients = () => async dispatch => {
    const res = await axios.get('/api/clients')
    dispatch({ type: FETCH_CLIENTS, payload: res.data })
    console.log("fetchClients:")
    console.log(res.data)
}

export const editClient = (values, callback) => async dispatch => {
  await axios
    .put("/api/client/" + values._id, values)
    .then(response => {
        console.log("editClient success")
        dispatch({ type: EDIT_CLIENT, payload: { invalidResponse: false } })
        callback(true)
    })
    .catch(err => {
        console.log("editClient failure")
        dispatch({ type: EDIT_CLIENT, payload: { invalidResponse: true, error: err } })
         callback(false)
    })
}

export const deleteClient = (values, callback) => async dispatch => {
  await axios
    .delete("/api/client/" + values.id, values)
    .then(response => {
        dispatch({ type: DELETE_CLIENT, payload: { invalidResponse: false } })
        callback()
    })
    .catch(err => {
        dispatch({ type: DELETE_CLIENT, payload: { invalidResponse: true, error: err } })
        callback()
    })
}


export const createUser = (body, callback) => dispatch => {
    axios.post('/api/user/create', body)
    .then((res) => {
        console.log("createUser success")
        dispatch({ type: CREATE_USER, payload: { createUserFailure: false } })
        callback(true, res.data._id)
    })
    .catch((err) => {
        console.log(`createUser failed with error: ${err}`)
        dispatch({ type: CREATE_USER, payload: { createUserFailure: true } })
        callback(false)
    })
}

export const fetchUsers = () => async dispatch => {
    const res = await axios.get('/api/users')
    dispatch({ type: FETCH_USERS, payload: res.data })
}

export const editUser = (values, callback) => async dispatch => {
  await axios
    .put("/api/user/" + values._id, values)
    .then(response => {
        console.log("editUser success")
        dispatch({ type: EDIT_USER, payload: { invalidResponse: false } })
        callback(true)
    })
    .catch(err => {
        console.log("editUser failure")
        console.log(err)
        dispatch({ type: EDIT_USER, payload: { invalidResponse: true, error: err } })
         callback(false)
    })
}

export const userAuditTrailLogin = (id, callback) => async dispatch => {
  await axios
    .post("/api/user/" + id + "/audit_trail/login")
    .then(response => {
        console.log("userAuditTrailLogin success")
        dispatch({ type: EDIT_USER, payload: { invalidResponse: false } })
        callback(true)
    })
    .catch(err => {
        console.log("userAuditTrailLogin failure")
        console.log(err)
        dispatch({ type: EDIT_USER, payload: { invalidResponse: true, error: err } })
         callback(false)
    })
}

export const userAuditTrailCreateClient = (id, body, callback) => async dispatch => {
  await axios
    .post("/api/user/" + id + "/audit_trail/create_client", body)
    .then(response => {
        console.log("userAuditTrailCreateClient success")
        dispatch({ type: EDIT_USER, payload: { invalidResponse: false } })
        callback(true)
    })
    .catch(err => {
        console.log("userAuditTrailCreateClient failure")
        console.log(err)
        dispatch({ type: EDIT_USER, payload: { invalidResponse: true, error: err } })
         callback(false)
    })
}

export const userAuditTrailEditClient = (id, body, callback) => async dispatch => {
  await axios
    .post("/api/user/" + id + "/audit_trail/edit_client", body)
    .then(response => {
        console.log("userAuditTrailEditClient success")
        dispatch({ type: EDIT_USER, payload: { invalidResponse: false } })
        callback(true)
    })
    .catch(err => {
        console.log("userAuditTrailEditClient failure")
        console.log(err)
        dispatch({ type: EDIT_USER, payload: { invalidResponse: true, error: err } })
         callback(false)
    })
}

export const userAuditTrailDownloadClientInfoForm = (id, body, callback) => async dispatch => {
  await axios
    .post("/api/user/" + id + "/audit_trail/download_client_info_form", body)
    .then(response => {
        console.log("userAuditTrailDownloadClientInfoForm success")
        dispatch({ type: EDIT_USER, payload: { invalidResponse: false } })
        callback(true)
    })
    .catch(err => {
        console.log("userAuditTrailDownloadClientInfoForm failure")
        console.log(err)
        dispatch({ type: EDIT_USER, payload: { invalidResponse: true, error: err } })
         callback(false)
    })
}

export const deleteUser = (values, callback) => async dispatch => {
  await axios
    .delete("/api/user/" + values.id, values)
    .then(response => {
        dispatch({ type: DELETE_USER, payload: { invalidResponse: false } })
        callback()
    })
    .catch(err => {
        dispatch({ type: DELETE_USER, payload: { invalidResponse: true, error: err } })
        callback()
    })
}


export const createRole = (body, callback) => dispatch => {
    axios.post('/api/role/create', body)
    .then((res) => {
        console.log("createRole success")
        dispatch({ type: CREATE_ROLE, payload: { createRoleFailure: false } })
        callback(true, res.data)
    })
    .catch((err) => {
        console.log(`createRole failed with error: ${err}`)
        dispatch({ type: CREATE_ROLE, payload: { createRoleFailure: true } })
        callback(false)
    })
}

export const fetchRoles = () => async dispatch => {
    const res = await axios.get('/api/roles')
    dispatch({ type: FETCH_ROLES, payload: res.data })
}

export const editRole = (values, callback) => async dispatch => {
  await axios
    .put("/api/role/" + values._id, values)
    .then(response => {
        console.log("editRole success")
        dispatch({ type: EDIT_ROLE, payload: { invalidResponse: false } })
        callback(true)
    })
    .catch(err => {
        console.log("editRole failure")
        console.log(err)
        dispatch({ type: EDIT_ROLE, payload: { invalidResponse: true, error: err } })
         callback(false)
    })
}

export const deleteRole = (values, callback) => async dispatch => {
  await axios
    .delete("/api/role/" + values.id, values)
    .then(response => {
        dispatch({ type: DELETE_ROLE, payload: { invalidResponse: false } })
        callback()
    })
    .catch(err => {
        dispatch({ type: DELETE_ROLE, payload: { invalidResponse: true, error: err } })
        callback()
    })
}