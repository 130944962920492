import React, { useState, useEffect } from 'react';
import './Carousell.css';
import { Image, Container, Button } from 'semantic-ui-react'

const imageStyle = { width: '100%', height: '66em' }
const h1Style = { position: 'absolute', top: '8.9em', textAlign: 'center', width: '100%', fontSize: '3.5em', fontFamily: 'Helvetica, Arial, sans-serif', color: 'white', textShadow: 'black 1px 1px 3px' }
const h2Style = { position: 'absolute', top: '20.1em', textAlign: 'center', width: '100%', fontSize: '1.8em', fontFamily: 'Helvetica, Arial, sans-serif', color: 'white', textShadow: 'black 1px 1px 3px', fontWeight: '100' }

const Carousell = () => {
  const [visibleDiv, setVisibleDiv] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setVisibleDiv((prevDiv) => (prevDiv === 0 ? 1 : 0));
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Container fluid className='container-carousell'>
        <div className={`fadeC ${visibleDiv === 0 ? 'showC' : ''}`}>
        <div className='contentC'>
            <Image fluid src='/breeze.jpg' style={imageStyle}/>
            <h1 style={h1Style}>
                Compliance can be a breeze with FMD</h1>
            <h2 style={h2Style}>
                We have helped thousands of clients maintain a stellar track record with Singapore regulatory authorities</h2>
            <Button size='large' color='#b4002c' style={{ position: 'absolute', margin: 0, top: '39em', left: '50%', transform: 'translate(-50%, -50%)', paddingLeft: '3em', paddingRight: '3em', paddingBottom: '1em', backgroundColor: '#b4002c', color: 'white' }}>
                Book a Consultation</Button>
            </div>
        </div>
        <div className={`fadeC ${visibleDiv === 1 ? 'showC' : ''}`}>
            <div className='contentC'>
            <Image fluid src='/sunrise.jpg' style={imageStyle}/>
            <h1 style={h1Style}>
                Every sunrise is an invitation for us to excel</h1>
            <h2 style={h2Style}>
                Our enduring testimonials speak loudly about us from our clients</h2>
            <Button size='large' style={{ position: 'absolute', margin: 0, top: '39em', left: '50%', transform: 'translate(-50%, -50%)', paddingLeft: '3em', paddingRight: '3em', paddingBottom: '1em', backgroundColor: '#b4002c', color: 'white' }}>
                Book a Consultation</Button>
            </div>
        </div>
    </Container>
  )
}

export default Carousell;