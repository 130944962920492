import React, { Component } from "react"
import { format } from "date-fns"

import { Container, Label, LabelDetail, Icon } from 'semantic-ui-react'

class AuditTrail extends Component {
    state = {}

    renderAuditTrail() {
        if (this.props.auditTrail.editedBy != null) {
            var arr = []
            let formattedDate = format(this.props.auditTrail.createdAt, "d MMMM, yyyy hh:mm a")
            let createdByString = `Created by ${this.props.auditTrail.createdBy}`
            let createdAtString = `at ${formattedDate}`
            arr.push(
                <div>
                    <Label color="black" size="large">
                        <Icon name='add' />
                        {createdByString}<LabelDetail>{createdAtString}</LabelDetail>
                    </Label>
                    <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;⬇<br />
                </div>
            )
            for (var i = 0; i < this.props.auditTrail.editedBy.length; i++) {
                let formattedDate2 = format(this.props.auditTrail.editedAt[i], "d MMMM, yyyy hh:mm a")
                let editedByString = `Edited by ${this.props.auditTrail.editedBy[i]}`
                let editedAtString = `at ${formattedDate2}`
                arr.push(
                    <div>
                        <Label color="orange" size="large">
                            <Icon name='pencil' />
                            {editedByString}<LabelDetail>{editedAtString}</LabelDetail>
                        </Label>
                        <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;⬇<br />
                    </div>
                )
            }
            arr.push(<div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;<Label color="black" size="large">End of trail</Label><br /><br /><br /></div>)
            return arr
        } else {
            return
        }
    }

    render() {
        return (
            <Container>
                <h3>Audit Trail</h3>
                {this.renderAuditTrail()}
            </Container>
        )
    }
}

export default AuditTrail