import React, { Component } from "react"
import { connect } from "react-redux"
import * as actions from "../../../actions"

import { Container, Breadcrumb, BreadcrumbSection, BreadcrumbDivider, Form, FormGroup, FormField, Input, Select, TextArea, Button } from 'semantic-ui-react'

const buttonStyle = {
    marginRight: "15px",
}

var oldRole = {}

class NewUser extends Component {
    state = { }
  
    constructor(props) {
        super(props)
        this.handleSaveClicked = this.handleSaveClicked.bind(this)
        this.handleCancelClicked = this.handleCancelClicked.bind(this)
    }

    componentDidMount() {
        if (this.props.isEditing) {
            const user = this.props.userToEdit
            this.setState({
                name: user.name,
                email: user.email,
                password: user.password,
                role: user.role,
                remarks: user.remarks
            })
            if (!!this.props.roles && user.role) {
                for (let role of this.props.roles) {
                    if (role._id === user.role.id) {
                        oldRole = role
                        break
                    }
                }
            }
        }
    }

    handleChangeField = e => {
        if (this.props.viewOnly) {
            if (this.state[e.target.name] == null) {
                this.setState({ [e.target.name]: ""})
            }
            return
        }
        const { name, value } = e.target
        this.setState({ [name]: value })
    }
    handleChangeRoleDropDown = (e, data) => {
        var id = ""
        for (let option of data.options) {
            if (option.value === e.target.innerText) { // this assumes no 2 roles uses the same name
                id = option.key
            }
        }
        let role = { name: e.target.innerText, id }
        this.setState({ role })
    }

    renderRoleField() {
        const { role } = this.state
        if (!!role) {
            if (role.name.toLowerCase() === 'superadmin') {
                return(<FormField control={Input} disabled label='Role' name='role' value={role.name} placeholder=''/>)
            }
        }
        var arr = [] // intialise role dropdown options
        for (let role of this.props.roles) {
            if (role.name === "superadmin") {
                continue
            }
            let roleDict = { key: role._id, text: role.name, value: role.name }
            arr.push(roleDict)
        }
        var roleString = ""
        if (!!role) {
            roleString = role.name
        }
        return(<FormField control={Select} options={arr} label='Role' name='role' value={roleString} placeholder='Select role...' onChange={this.handleChangeRoleDropDown}/>)
    }
    
    renderForm() {
        const { name, email, password, remarks } = this.state
        let type = this.props.isEditing ? "password" : ""
        return (
            <Container>
                <Form>
                    <FormGroup widths={"equal"}>
                        <FormField control={Input} label='Name of Staff' name='name' value={name} placeholder='' onChange={this.handleChangeField}/>
                        {this.renderRoleField()}
                    </FormGroup>    
                    <FormGroup widths={"equal"}>
                        <FormField control={Input} label='Email' name='email' value={email} placeholder='' onChange={this.handleChangeField}/>
                        <FormField control={Input} label='Password' type={type} name='password' value={password} placeholder='' onChange={this.handleChangeField}/>
                    </FormGroup>
                    <FormField control={TextArea} rows='2' label='Remarks' name='remarks' value={remarks} placeholder='' onChange={this.handleChangeField}/>
                </Form>
            </Container>
        )
    }

    render() {
        var breadcrumbString = 'Create New Staff'
        var saveButtonString = 'Create Staff'
        if (this.props.isEditing) {
            breadcrumbString = this.props.userToEdit.name
            saveButtonString = 'Save Changes'
        }
        return (
            <Container>
                <br />
                <Breadcrumb size='large'>
                    <BreadcrumbSection onClick={this.props.onBackClicked}>My Staff</BreadcrumbSection>
                    <BreadcrumbDivider icon='right chevron' />
                    <BreadcrumbSection active>{breadcrumbString}</BreadcrumbSection>
                </Breadcrumb>
                <br /><br /><br />
                {this.renderForm()}
                <br /><br />
                <Button style={buttonStyle} color='red' onClick={this.handleSaveClicked}>{saveButtonString}</Button><Button onClick={this.handleCancelClicked}>Cancel and Go Back</Button>
                <br /><br /><br /><br /><br /><br /><br /><br />
            </Container>
        )
    }

    handleSaveClicked() {
        const { name, email, role, password, remarks } = this.state
        const dict = { createdBy: this.props.user.name, createdAt: Date(), name, email, role, password, remarks }
        if (this.props.isEditing) {
            dict['_id'] = this.props.userToEdit._id
            this.props.editUser(dict, (success) => {
                if (success) {
                    if (oldRole.id === role.id) {
                        this.props.onEditSuccess()
                    } else {
                        this.callAPIEditRoles()
                    }
                } else {
                    alert('Failure editing user, please try again')
                }
            })
        } else {
            this.props.createUser(dict, (success, user_id) => {
                if (success) {
                    if (role == null) {
                        this.props.onCreateSuccess()
                    } else {
                        this.callAPIEditRoles(user_id)
                    }
                } else {
                    alert('Failure creating user, please try again')
                }
            })
        }
    }
    
    callAPIEditRoles(user_id) {
        const { role } = this.state
        console.log(oldRole)
        if (this.props.isEditing) {
            if (Object.keys(oldRole).length !== 0) {
                // remove user from old role
                for (var i = 0; i < oldRole.users.length; i++) {
                    if (this.props.userToEdit._id === oldRole.users[i].id) {
                        oldRole.users.splice(i, 1)
                        break
                    }
                }
                const dict = { _id: oldRole._id, name: oldRole.name, users: oldRole.users, permissions: oldRole.permissions, remarks: oldRole.remarks }
                this.props.editRole(dict, (success,) => {
                    if (success) {
                        console.log(`removed user '${this.props.userToEdit.name}' from old role: ${oldRole.name}`)
                    } else {
                        alert('Failure editing role, please try again')
                    }
                })
            }
        }
        // add user to new role
        var newRole = {}
        for (let roleIteration of this.props.roles) {
            if (roleIteration._id === role.id) {
                newRole = roleIteration
                break
            }
        }
        var arr = [...newRole.users]
        let name = this.props.isEditing ? this.props.userToEdit.name : this.state.name
        let id = this.props.isEditing ? this.props.userToEdit._id : user_id
        arr.push({ name, id })
        const dict = { _id: newRole._id, name: newRole.name, users: arr, permissions: newRole.permissions, remarks: newRole.remarks }
        this.props.editRole(dict, (success,) => {
            if (success) {
                if (this.props.isEditing) {
                    console.log(`added user '${this.props.userToEdit.name}' into new role: ${newRole.name}`)
                    this.props.onEditSuccess()
                } else {
                    this.props.onCreateSuccess()
                }
            } else {
                alert('Failure editing role, please try again')
            }
        })
        
    }

    handleCancelClicked() {
        this.props.onCancel()
    }
}

function mapStateToProps({ user, roles }) {
    return { user, roles }
}

export default connect(mapStateToProps, actions)(NewUser)