import { React, Component } from 'react'
import { connect } from "react-redux"
import * as actions from "../../actions"
import './FMD.css'

import Carousell from './Carousell'
import Testimonials from './Testimonials'

import { Image, Menu, Container, Button, Grid, GridColumn, GridRow, Statistic, Divider, Segment, Icon } from 'semantic-ui-react'

const fontStyle1 = { fontFamily: 'Helvetica, Arial, sans-serif', fontSize: '2.5em', fontWeight: '400' }
const fontStyle2 = { fontFamily: 'Helvetica, Arial, sans-serif', fontSize: '1.5em', fontWeight: '200'}
const fontStyle3 = { fontFamily: 'Helvetica, Arial, sans-serif', fontSize: '1.2em', fontWeight: '300'}
const fontStyle4 = { fontFamily: 'Helvetica, Arial, sans-serif', fontSize: '1.2em', fontWeight: '300'}
const fontStyle5 = { fontFamily: 'Helvetica, Arial, sans-serif', fontSize: '2.5em', fontWeight: '400', paddingTop: '2em', color: 'white'}
const fontStyle6 = { fontFamily: 'Helvetica, Arial, sans-serif', fontSize: '1.2em', fontWeight: '300', color: 'white'}
const fontStyle7 = { fontFamily: 'Helvetica, Arial, sans-serif', fontSize: '1.8em', fontWeight: '600', paddingTop: '2em' }
const fontStyle8 = { fontFamily: 'Helvetica, Arial, sans-serif', fontSize: '1.5em', fontWeight: '600', paddingTop: '2em', color: '#b4002c' }
// const underlineStyle = { textDecorationColor: '#b4002c', fontWeight: '500' }

class FMD extends Component {
    
    state = { }

    renderNavBar() {
        return (
            <Menu stackable fixed='top' className='custom-navbar'>
                <Menu.Item header><Image src='/fmd-logo-elaborate.png' style={{ marginLeft: '5em', marginRight: '5em', width: '15em' }}/></Menu.Item>
                <Container text style={{ offsetPosition: 'left 1em' }}>
                    <Menu.Item className='no-border-item'>About</Menu.Item><Menu.Item className='no-border-item'>Industry Insights</Menu.Item><Menu.Item className='no-border-item'>Services</Menu.Item>
                    <Menu.Item className='no-border-item' position='right'><Button style={{ backgroundColor: '#b4002c', color: 'white' }}>Contact Us</Button></Menu.Item>
                </Container>
            </Menu>
        )
    }

    renderTrackRecord() {
        return (
            <Container style={{ textAlign: 'center', width: '100%', position: 'relative', top: '72em'}}>
                <h2 style={fontStyle1}>Choose a credible and established accounting partner.</h2>
                <h3 style={fontStyle2}>Our customers pick us over our <u>remarkable track record</u>.</h3>
                <Container style={{ marginTop: '5em' }}>
                    <Grid columns='equal'>
                        <GridRow>
                            <GridColumn><Statistic label='Happy Clients' value='1,000+' /></GridColumn>
                            <GridColumn><Statistic label='Accounting years' value='20+' /></GridColumn>
                            <GridColumn><Statistic label='Dedicated employees' value='40+' /></GridColumn>
                            <GridColumn><Statistic label='Incorporations' value='1,000+' /></GridColumn>
                        </GridRow>
                        <GridRow>
                            <GridColumn><h3 style={fontStyle3}>We have experience through different accounting situations</h3></GridColumn>
                            <GridColumn><h3 style={fontStyle3}>We have gone through many accounting years together with our clients</h3></GridColumn>
                            <GridColumn><h3 style={fontStyle3}>We have a dedicated team of well-trained professionals</h3></GridColumn>
                            <GridColumn><h3 style={fontStyle3}>We have helped to set up more than 1000 companies in Singapore over the last 10 years.</h3></GridColumn>
                        </GridRow>
                    </Grid>
                </Container>
                <Divider style={{ marginTop: '7em'}}/>
            </Container>
        )
    }

    renderAwards() {
        return (
            <Container style={{ textAlign: 'center', width: '100%', position: 'relative', top: '78em'}}>
                <h2 style={fontStyle1}> We are well-recognised in Singapore's business landscape.</h2>
                <h3 style={fontStyle2}>Here are some of our <u>Awards and Accreditations</u>:</h3>
                <Container style={{ marginTop: '4em' }}>
                    <Grid columns='equal'>
                        <GridRow style={{ paddingBottom: '0em' }}>
                            <GridColumn><Image centered src='/award-1.png' style={{ width: '33em', height: '10em', objectFit: 'cover' }}/></GridColumn>
                            <GridColumn><Image centered src='/award-2.png' style={{ width: '24em', height: '10em', objectFit: 'cover' }}/></GridColumn>
                        </GridRow>
                        <GridRow style={{ marginBottom: '5.5em' }}>
                            <GridColumn><h3 style={fontStyle4}>20 years of membership milestone with ISCA</h3></GridColumn>
                            <GridColumn><h3 style={fontStyle4}>Member of a trusted, global network with over 1.8 million members</h3></GridColumn>
                        </GridRow>
                        <GridRow style={{ paddingBottom: '0em' }}>
                            <GridColumn><Image centered src='/award-3.png' style={{ width: '24em', height: '10em', objectFit: 'cover' }}/></GridColumn>
                            <GridColumn><Image centered src='/award-4.png' style={{ width: '13em', height: '9em', objectFit: 'cover' }}/></GridColumn>
                            <GridColumn><Image centered src='/award-5.png' style={{ width: '16em', height: '8em', objectFit: 'cover' }}/></GridColumn>
                        </GridRow>
                        <GridRow style={{ marginBottom: '5em' }}>
                            <GridColumn><h3 style={fontStyle4}>OCBC Gold/Valued Partner's Award</h3></GridColumn>
                            <GridColumn><h3 style={fontStyle4}>Top 15 E-filing Tax Agents</h3></GridColumn>
                            <GridColumn><h3 style={fontStyle4}>ACRA Approved Registered Filing Agent</h3></GridColumn>
                        </GridRow>
                        <GridRow style={{ paddingBottom: '0em' }}>
                            <GridColumn><Image centered src='/award-6.png' style={{ width: '9.5em', height: '9.5em', objectFit: 'cover' }}/></GridColumn>
                            <GridColumn><Image centered src='/award-7.png' style={{ width: '24em', height: '8.5em', objectFit: 'cover' }}/></GridColumn>
                        </GridRow>
                        <GridRow style={{ marginBottom: '5em' }}>
                            <GridColumn><h3 style={fontStyle4}>20 years of membership milestone with ISCA</h3></GridColumn>
                            <GridColumn><h3 style={fontStyle4}>Member of a trusted, global network with over 1.8 million members</h3></GridColumn>
                        </GridRow>
                    </Grid>
                </Container>
            </Container>
        )
    }

    renderSatisfaction() {
        return (
            <Container style={{ textAlign: 'center', width: '100%', height: '34em', position: 'relative', top: '82em', backgroundColor: '#22262C'}}>
                <h2 style={fontStyle5}>99.69% satisfaction rate for all our services.</h2>
                <h3 style={fontStyle6}>In our latest survey conducted in 2022, we are glad to receive the following positive reviews from <u>485 surveyed clients</u> for our services:</h3>
                <Container style={{ marginTop: '5em' }}>
                    <Grid columns='equal'>
                        <GridRow>
                            <GridColumn><Statistic><Statistic.Value style={{ color: '#b4002c' }}>99.79%</Statistic.Value><Statistic.Label style={{ color: '#FFFFFF' }}>loved our expertise</Statistic.Label></Statistic></GridColumn>
                            <GridColumn><Statistic><Statistic.Value style={{ color: '#b4002c' }}>99.38%</Statistic.Value><Statistic.Label style={{ color: '#FFFFFF' }}>said we are very timely</Statistic.Label></Statistic></GridColumn>
                            <GridColumn><Statistic><Statistic.Value style={{ color: '#b4002c' }}>99.79%</Statistic.Value><Statistic.Label style={{ color: '#FFFFFF' }}>said we are friendly</Statistic.Label></Statistic></GridColumn>
                        </GridRow>
                        <GridRow>
                        <GridColumn><h3 style={fontStyle6}>We are able to deliver.</h3></GridColumn>
                        <GridColumn><h3 style={fontStyle6}>We deliver our services on time.</h3></GridColumn>
                        <GridColumn><h3 style={fontStyle6}>It's important to have an accountant you can communicate with ease.</h3></GridColumn>
                        </GridRow>
                    </Grid>
                </Container>
            </Container>
        )
    }

    renderServices() {
        return (
            <Container style={{ textAlign: 'center', width: '100%', position: 'relative', top: '90em'}}>
                <h2 style={fontStyle1}>Our Business Services</h2>
                <h3 style={fontStyle2}>Our expertise allows us to provide a wide breadth of services to service our customers throughout the <u>different stages of their business</u>.
                <p style={{ marginBottom: '0.8em' }} />Pick an accounting partner who is sincere to build a long term relationship with your company.</h3>
                <Segment style={{ marginTop: '4em' }}>
                    <h3 style={fontStyle7}>Starting a company in Singapore</h3>
                    <Container style={{ marginTop: '2.5em' }}>
                        <Grid columns='equal'>
                            <GridRow style={{ paddingBottom: '0em' }}>
                                <GridColumn><Image centered src='/service-1-1.png' style={{ width: '9em', height: '9em', objectFit: 'cover', top: '1.5em', paddingBottom: '0' }}/></GridColumn>
                                <GridColumn><Image centered src='/service-1-2.png' style={{ width: '9em', height: '9em', objectFit: 'cover' }}/></GridColumn>
                                <GridColumn><Image centered src='/service-1-3.png' style={{ width: '8em', height: '8em', objectFit: 'cover' }}/></GridColumn>
                            </GridRow>
                            <GridRow style={{ marginBottom: '1em', paddingTop: '0' }}>
                                <GridColumn><h3 style={fontStyle8}>Company Registration</h3><p /><h4 style={fontStyle4}>Starting a company?<br />We can set it up for you in a breeze.</h4></GridColumn>
                                <GridColumn><h3 style={fontStyle8}>Registered Address</h3><p /><h4 style={fontStyle4}>Conveniently located at Mountbatten.</h4></GridColumn>
                                <GridColumn><h3 style={fontStyle8}>Nominee Director</h3><p /><h4 style={fontStyle4}>We can assist you in the nomination of a directorship.</h4></GridColumn>
                            </GridRow>
                            <GridRow style={{ marginBottom: '4.5em', paddingTop: '0' }}>
                                <GridColumn>
                                <Button size='large' color='#b4002c' style={{ backgroundColor: '#b4002c', color: 'white' }}>
                                    Learn more</Button>
                                </GridColumn>
                                <GridColumn>
                                <Button size='large' color='#b4002c' style={{ backgroundColor: '#b4002c', color: 'white' }}>
                                Learn more</Button>
                                </GridColumn>
                                <GridColumn>
                                    <Button size='large' color='#b4002c' style={{ backgroundColor: '#b4002c', color: 'white' }}>
                                    Learn more</Button>
                                </GridColumn>
                            </GridRow>
                        </Grid>
                    </Container>
                </Segment>
                <Segment style={{ marginTop: '0em' }}>
                    <h3 style={fontStyle7}>Running your Company</h3>
                    <Container style={{ marginTop: '3em' }}>
                        <Grid columns='equal'>
                            <GridRow style={{ paddingBottom: '0em' }}>
                                <GridColumn><Image centered src='/service-2-1.png' style={{ width: '5em', height: '5em', objectFit: 'cover', paddingBottom: '0' }}/></GridColumn>
                                <GridColumn><Image centered src='/service-2-2.png' style={{ width: '5em', height: '5em', objectFit: 'cover' }}/></GridColumn>
                                <GridColumn><Image centered src='/service-2-3.png' style={{ width: '5em', height: '5em', objectFit: 'cover' }}/></GridColumn>
                                <GridColumn><Image centered src='/service-2-4.png' style={{ width: '5em', height: '5em', objectFit: 'cover', paddingBottom: '0' }}/></GridColumn>
                            </GridRow>
                            <GridRow style={{ marginBottom: '1em', paddingTop: '0' }}>
                                <GridColumn><h3 style={fontStyle8}>Outsourced Accounting</h3><p /><h4 style={fontStyle4}>We are your accounting department –– we do the papers, you do the business.</h4></GridColumn>
                                <GridColumn><h3 style={fontStyle8}>Audit</h3><p /><h4 style={fontStyle4}>Need to do a statutory audit or fraud investigation? Our audit experts can help you.</h4></GridColumn>
                                <GridColumn><h3 style={fontStyle8}>Corporate Secretarial</h3><p /><h4 style={fontStyle4}>Let us handle your secretarial matters to abide by the law.</h4></GridColumn>
                                <GridColumn><h3 style={fontStyle8}>Tax</h3><p /><h4 style={fontStyle4}>Optimise the tax reliefs and exemptions granted to your business.</h4></GridColumn>
                            </GridRow>
                            <GridRow style={{ marginBottom: '4em', paddingTop: '0' }}>
                                <GridColumn>
                                <Button size='medium' color='#b4002c' style={{ backgroundColor: '#b4002c', color: 'white' }}>
                                    Learn more</Button>
                                </GridColumn>
                                <GridColumn>
                                <Button size='medium' color='#b4002c' style={{ backgroundColor: '#b4002c', color: 'white' }}>
                                Learn more</Button>
                                </GridColumn>
                                <GridColumn>
                                    <Button size='medium' color='#b4002c' style={{ backgroundColor: '#b4002c', color: 'white' }}>
                                    Learn more</Button>
                                </GridColumn>
                                <GridColumn>
                                    <Button size='medium' color='#b4002c' style={{ backgroundColor: '#b4002c', color: 'white' }}>
                                    Learn more</Button>
                                </GridColumn>
                            </GridRow>
                            <GridRow style={{ paddingBottom: '0em' }}>
                                <GridColumn><Image centered src='/service-2-5.png' style={{ width: '5em', height: '5em', objectFit: 'cover' }}/></GridColumn>
                                <GridColumn><Image centered src='/service-2-6.png' style={{ width: '5em', height: '5em', objectFit: 'cover', paddingBottom: '0' }}/></GridColumn>
                                <GridColumn><Image centered src='/service-2-7.png' style={{ width: '5em', height: '5em', objectFit: 'cover' }}/></GridColumn>
                                <GridColumn><Image centered src='/service-2-8.png' style={{ width: '5em', height: '5em', objectFit: 'cover' }}/></GridColumn>
                            </GridRow>
                            <GridRow style={{ marginBottom: '1em', paddingTop: '0' }}>
                                <GridColumn><h3 style={fontStyle8}>Financial statements</h3><p /><h4 style={fontStyle4}>Leave the compilation of financial statements to us.</h4></GridColumn>
                                <GridColumn><h3 style={fontStyle8}>Payroll & Administration</h3><p /><h4 style={fontStyle4}>Make sure your payroll is made on time with confidentiality.</h4></GridColumn>
                                <GridColumn><h3 style={fontStyle8}>Preparation of XBRL</h3><p /><h4 style={fontStyle4}>XBRL is the language for the electronic communication of business and financial data worldwide.</h4></GridColumn>
                                <GridColumn><h3 style={fontStyle8}>Cloud Accounting</h3><p /><h4 style={fontStyle4}>Let us advise you on implementing cloud accounting for your business.</h4></GridColumn>
                            </GridRow>
                            <GridRow style={{ marginBottom: '8em', paddingTop: '0' }}>
                                <GridColumn>
                                <Button size='medium' color='#b4002c' style={{ backgroundColor: '#b4002c', color: 'white' }}>
                                    Learn more</Button>
                                </GridColumn>
                                <GridColumn>
                                <Button size='medium' color='#b4002c' style={{ backgroundColor: '#b4002c', color: 'white' }}>
                                Learn more</Button>
                                </GridColumn>
                                <GridColumn>
                                <Button size='medium' color='#b4002c' style={{ backgroundColor: '#b4002c', color: 'white' }}>
                                Learn more</Button>
                                </GridColumn>
                                <GridColumn>
                                <Button size='medium' color='#b4002c' style={{ backgroundColor: '#b4002c', color: 'white' }}>
                                Learn more</Button>
                                </GridColumn>
                            </GridRow>
                        </Grid>
                    </Container>
                </Segment>
            </Container>
        )
    }

    renderTestimonials() {
        return (
            <Container style={{ textAlign: 'center', width: '100%', position: 'relative', top: '97em' }}>
                <h2 style={fontStyle1}>We received glowing testimonials from our esteemed clients.</h2>
                <Testimonials />
                <Divider style={{ marginTop: '34em'}}/>
            </Container>
        )
    }

    renderBookConsultation() {
        return (
            <Container style={{ textAlign: 'center', width: '100%', position: 'relative', top: '96em' }}>
                <Image centered src='/book-consultation.jpg' style={{ width: '33em', height: '33em', objectFit: 'cover' }}/>
                <h2 style={fontStyle1}>Ready to relieve your compliance headaches with us?</h2>
                <h3 style={fontStyle2}>Schedule a free consultation with our consultants.</h3>
                <Button size='huge' color='#b4002c' style={{ backgroundColor: '#b4002c', color: 'white', marginTop: '1em' }}>Book a consultation</Button>
            </Container>
        )
    }

    renderFooter() {
        return (
            <Container style={{ textAlign: 'center', width: '100%', height: '34em', position: 'relative', top: '105em', backgroundColor: '#22262C'}}>
                <Container>
                    <Grid columns='equal'>
                        <GridColumn>
                            <Image src='/footer-1.png' style={{ width: '12em', paddingTop: '3em', objectFit: 'cover' }}/>
                            <h4 style={{ textAlign: 'left', color: 'white', fontWeight: '300' }}>FMD group started in 1999, with the goal to provide quality service<br />at a fair price to assist SMEs in their journey towards success.</h4>
                            <Grid columns='equal' style={{ paddingTop: '2em' }}>
                                <GridRow>
                                    <GridColumn>
                                        <Icon inverted name='location arrow' size='large'/>
                                    </GridColumn>
                                    <GridColumn>
                                        <Icon inverted name='phone' size='large'/>
                                    </GridColumn>
                                </GridRow>
                            </Grid>
                            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                            <h4 style={{ textAlign: 'left', color: 'white' }}>© Copyright 2024 - FMD Group</h4>
                        </GridColumn>
                        <GridColumn>
                            Google Maps
                        </GridColumn>
                    </Grid>
                </Container>
            </Container>
        )
    }

    render() {
        return (
            <div>  
                {this.renderNavBar()}
                <Carousell />
                {this.renderTrackRecord()}
                {this.renderAwards()}
                {this.renderSatisfaction()}
                {this.renderServices()}
                {this.renderTestimonials()}
                {this.renderBookConsultation()}
                {this.renderFooter()}
                <Container style={{ textAlign: 'center', width: '100%', position: 'relative', top: '110em'}}>
                    .
                </Container>
            </div>
        )
    }
}

function mapStateToProps({ auth, verifyToken }) {
    return { auth, verifyToken }
}

export default connect(mapStateToProps, actions)(FMD)