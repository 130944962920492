import { React, Component } from 'react'
import { connect } from "react-redux"
import * as actions from "../actions"

import { HeaderContent, Header, Image } from 'semantic-ui-react'

class TwoFAPage extends Component {
    
    state = { }

    renderFeatureCards() {
        return (
            <div>
                <h3>test</h3> 
            </div>
        )
    }
    
    render() {
        return (
            <div>
            <br />
            <br />
            <Image size='large' centered src='/bubbly-logo.png'/>
            <Header as='h2' icon textAlign='center'>
                <HeaderContent>Hello</HeaderContent>
            </Header>
            <br />
            {this.renderFeatureCards()}
            </div>
        )
    }
}

function mapStateToProps({ auth, verifyToken }) {
    return { auth, verifyToken }
}

export default connect(mapStateToProps, actions)(TwoFAPage)