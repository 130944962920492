import React, { Component } from "react"
import { fields } from "../../../../fields"

import { Container, Form, FormGroup, FormField, TextArea, Radio, Input } from 'semantic-ui-react'

const departmentName = 'Outsourced Accounting'

class OutsourcedAccounting extends Component {
    state = {}

    componentDidMount() {
        var savedDict = {}
        if (this.props.savedDict) {
            savedDict = this.props.savedDict
        } else {
            return
        }
        this.setState({
            typeOfService: savedDict.typeOfService,
            dateOfUpdate: savedDict.dateOfUpdate,
            principalStaff: savedDict.principalStaff,
            remarks: savedDict.remarks
        })
    }

    handleChangeField = e => {
        if (this.props.viewOnly) {
            if (this.state[e.target.name] == null) {
                this.setState({ [e.target.name]: ""})
            }
            return
        }
        const { name, value } = e.target
        if (!getWritePermissionForField(name, this.props.permissions) && this.state[e.target.name] == null) {
            this.setState({ [e.target.name]: ""})
            return
        }   
        else if (!getWritePermissionForField(name, this.props.permissions)) return
        this.setState({ [name]: value }, () =>
            this.props.onStateChange(this.state)
        )
    }
    handleChangeTypeOfService = (e, { value }) => {
        if (this.props.viewOnly) return
        if (!getWritePermissionForField('typeOfService', this.props.permissions)) return
        this.setState({ typeOfService: value }, () =>
            this.props.onStateChange(this.state)
        )
    }

    getFieldStyle(name) {
        if (!getReadPermissionForField(name, this.props.permissions)) {
            return { visibility: 'hidden' }
        }
        return { visibility: 'visible' }
    }

    render() {
        const { typeOfService, dateOfUpdate, principalStaff, remarks } = this.state
        return (
            <Container>
                <Form>
                    <FormGroup style={this.getFieldStyle('typeOfService')} inline>
                        <label>Type of Service:</label>
                        <FormField control={Radio} label='Periodic' value='periodic' checked={typeOfService === 'periodic'} onChange={this.handleChangeTypeOfService}/>
                        <FormField control={Radio} label='Yearly' value='yearly' checked={typeOfService === 'yearly'} onChange={this.handleChangeTypeOfService}/>
                        <FormField control={Radio} label='Others' value='others' checked={typeOfService === 'others'} onChange={this.handleChangeTypeOfService}/>
                    </FormGroup>
                    <FormField style={this.getFieldStyle('dateOfUpdate')} inline control={Input} label='Date of Update' type='date' name='dateOfUpdate' value={dateOfUpdate} placeholder='' onChange={this.handleChangeField}/>
                    <FormField style={this.getFieldStyle('principalStaff')} control={Input} label='Principal Staff' name='principalStaff' value={principalStaff} placeholder='' onChange={this.handleChangeField}/>
                    <FormField style={this.getFieldStyle('remarks')} control={TextArea} rows='2' label='Remarks' name='remarks' value={remarks} placeholder='' onChange={this.handleChangeField}/>
                </Form>
            </Container>
        )
    }
}

function getWritePermissionForField(fieldName, permissions) {
    var key = ""
    for (let field of fields) {
        if (field.field === fieldName && field.departmentName === departmentName) {
            key = field.key
        }
    }
    for (let permission of permissions) {
        if (permission.key === key) {
            if (permission.write === false) {
            }
            return permission.write
        }
    }
}

function getReadPermissionForField(fieldName, permissions) {
    var key = ""
    for (let field of fields) {
        if (field.field === fieldName && field.departmentName === departmentName) {
            key = field.key
        }
    }
    for (let permission of permissions) {
        if (permission.key === key) {
            return permission.read
        }
    }
}

export default OutsourcedAccounting