import React, { Component } from "react"
import { connect } from "react-redux"
import * as actions from "../../../actions"
import { fields, departments } from "../../../fields"

import { Container, Breadcrumb, BreadcrumbSection, BreadcrumbDivider, Form, FormField, Divider, Header, Icon, Input, TextArea, Button, 
        TableRow, TableHeaderCell, TableHeader, TableCell, TableBody, Table, Checkbox, Label, Grid, GridColumn } from 'semantic-ui-react'

const labelStyle = {
    marginTop: "5px",
    marginRight: "8px",
    marginBottom: "5px"
}

const buttonStyle = {
    marginRight: "15px",
}

class NewRole extends Component {
    state = { unassignedUsers: [], originalUsers: [] } // unassignedUsers is only used in editing mode, when we need to keep track of the users removed from this role upon save
  
    constructor(props) {
        super(props)
        this.handleSaveClicked = this.handleSaveClicked.bind(this)
        this.handleCancelClicked = this.handleCancelClicked.bind(this)
        this.handleLeftUserClicked = this.handleLeftUserClicked.bind(this)
        this.handleRightUserClicked = this.handleRightUserClicked.bind(this)
    }

    componentDidUpdate(previousProps) {
        if (previousProps.users !== this.props.users) {
            var availableUsers = []
            var unavailableUsers = []
            var assignedUsers = []
            var originalUsers = []
            for (let user of this.props.users) {
                if (!!user.role) {
                    if (!!user.role.name) {
                        if (user.role.name.toLowerCase() === "superadmin") {
                            continue
                        }
                    } 
                }
                if (user.role == null) {
                    availableUsers.push(user)
                } else {
                    if (!this.props.isEditing) {
                        unavailableUsers.push(user)
                    } else {
                        if (user.role.id == null) {
                            availableUsers.push(user)
                        } else {
                            if (user.role.id === this.props.roleToEdit._id) {
                                assignedUsers.push(user)
                            } else {
                                unavailableUsers.push(user)
                            }
                        }
                    }   
                }
            }
            if (this.props.isEditing) {
                originalUsers = [...this.props.roleToEdit.users]
                this.setState({ canExportClientInfoForm: this.props.roleToEdit.canExportClientInfoForm, canFilterByFYE: this.props.roleToEdit.canFilterByFYE, canFilterByMonthOfAgreement: this.props.roleToEdit.canFilterByMonthOfAgreement })
            }
            this.setState({
                availableUsers, unavailableUsers, assignedUsers, originalUsers
            })
        }
    }

    componentDidMount() {
        this.props.fetchUsers()
        if (this.props.isEditing) {
            const role = this.props.roleToEdit
            var users = []
            for (var i = 0; i < role.users; i++) {
                var user = role.users[i]
                user.isOG = true
                users.append(user)
            }
            this.setState({
                name: role.name,
                remarks: role.remarks,
                users: role.users,
                permissions: role.permissions
            })
        } else {
            this.setState({
                permissions: inflatePermissions()
            })
        }
    }

    handleChangeField = e => {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }

    handleToggleCanExportClientInfoForm = (e, data) => {
        this.setState({
            canExportClientInfoForm: data.checked
        })
    }

    handleToggleCanFilterByFYE = (e, data) => {
        this.setState({
            canFilterByFYE: data.checked
        })
    }

    handleToggleCanFilterByMonthOfAgreement = (e, data) => {
        this.setState({
            canFilterByMonthOfAgreement: data.checked
        })
    }

    handleToggleFieldCanView = (e, data) => {
        this.setState({
            permissions: setReadPermission(data.name, data.checked, this.state.permissions)
        })
        if (!data.checked) {
            this.setState({
                permissions: setWritePermission(data.name, data.checked, this.state.permissions)
            })
        }
    }

    handleToggleFieldCanEdit = (e, data) => {
        this.setState({
            permissions: setWritePermission(data.name, data.checked, this.state.permissions)
        })
        if (data.checked) {
            this.setState({
                permissions: setReadPermission(data.name, data.checked, this.state.permissions)
            })
        }
    }

    handleToggleDeptCanView = (e, data) => {
        this.setState({
            permissions: setReadPermissionDept(data.name, data.checked, this.state.permissions)
        })
        if (!data.checked) {
            this.setState({
                permissions: setWritePermissionDept(data.name, data.checked, this.state.permissions)
            })
        }
    }

    handleToggleDeptCanEdit = (e, data) => {
        this.setState({
            permissions: setWritePermissionDept(data.name, data.checked, this.state.permissions)
        })
        if (data.checked) {
            this.setState({
                permissions: setReadPermissionDept(data.name, data.checked, this.state.permissions)
            })
        }
    }

    handleRightUserClicked(e, data) {
        var assignedUsers = [...this.state.assignedUsers]
        var unassignedUsers = [...this.state.unassignedUsers]
        var availableUsers = [...this.state.availableUsers]
        var selectedUser = JSON.parse(data.user)
        for (var user of availableUsers ) {
            if (user._id === selectedUser._id) {
                user.isClicked = true
            }
        }
        assignedUsers.push(selectedUser)
        if (this.props.isEditing) {
            // check if user is in the original list of user. if it is, find the user in unassignedUsers array and remove it.
            var isOriginalUser = false
            for (let originalUser of this.state.originalUsers) {
                if (user._id === originalUser._id) {
                    isOriginalUser = true
                }
            }
            if (isOriginalUser) {
                for (var i = 0; i < unassignedUsers.count; i++) {
                    if (unassignedUsers[i]._id === selectedUser._id) {
                        unassignedUsers.splice(i, 1)
                    }
                }
            }
        }
        this.setState({ assignedUsers, availableUsers, unassignedUsers })
    }

    handleLeftUserClicked(e, data) {
        var assignedUsers = [...this.state.assignedUsers]
        var unassignedUsers = [...this.state.unassignedUsers]
        var availableUsers = [...this.state.availableUsers]
        var selectedUser = JSON.parse(data.user)
        for (var i = 0; i < assignedUsers.length; i++ ) {
            let user = assignedUsers[i]
            if (user._id === selectedUser._id) {
                assignedUsers.splice(i, 1)
            }
        }
        if (this.props.isEditing) {
            // check if user is in the original list of user. if it is, append user to unassignedUsers AND availableUsers arrays.
            var isOriginalUser = false
            for (let originalUser of this.state.originalUsers) {
                console.log(originalUser)
                console.log(selectedUser)
                if (selectedUser._id === originalUser.id) {
                    isOriginalUser = true
                }
            }
            if (isOriginalUser) {
                unassignedUsers.push(selectedUser)
                var alreadyExist = false
                for (let user of availableUsers) {
                    if (user._id === selectedUser._id || user.id === selectedUser._id) {
                        alreadyExist = true
                        break
                    }
                }
                if (!alreadyExist) availableUsers.push(selectedUser)
            }
        } 
        for (var user of availableUsers ) {
            if (user._id === selectedUser._id) {
                user.isClicked = false
            }
        }
        availableUsers = [...new Set(availableUsers)]
        this.setState({ assignedUsers, availableUsers, unassignedUsers })
    }

    renderLeftUsers() {
        var rows = []
        if (this.state.assignedUsers == null) {
            return
        } else if (this.state.assignedUsers.length === 0) {
            return <h4>No staff assigned yet</h4>
        }
        for (let user of this.state.assignedUsers) {
            rows.push(
                <Label style={labelStyle} size='large' color="green">
                    {user.name}
                    <Icon name='delete' user={JSON.stringify(user)} onClick={this.handleLeftUserClicked}/>
                </Label>
            )
        }
        return rows
    }

    renderRightUsersWithoutRoles() {
        var rows = []
        if (this.state.availableUsers == null) {
            return
        } else if (this.state.availableUsers.length === 0) {
            return <Label color="red" size='large'>No staff available to assign</Label>
        }
        for (let user of this.state.availableUsers) {
            if (user.isClicked) {
                rows.push(
                    <Label style={labelStyle} size="large" user={JSON.stringify(user)}>{user.name}&nbsp;&nbsp;&nbsp;<Icon name='check'/></Label>
                )
            } else {
                rows.push(
                    <Label style={labelStyle} size="large" as='a' user={JSON.stringify(user)} color='blue' onClick={this.handleRightUserClicked}>{user.name}</Label>
                )
            }
        }
        return rows
    }

    renderRightUsersWithRoles() {
        var rows = []
        if (this.state.unavailableUsers == null) {
            return
        } else if (this.state.unavailableUsers.length === 0) {
            return <h4>No staff with role</h4>
        }
        for (let user of this.state.unavailableUsers) {
            rows.push(<Label style={labelStyle} size="large" user={JSON.stringify(user)} color='white'>{user.name}</Label>)
        }
        return rows
    }

    renderFields(dept) {
        var rows = []
        const isAllReadTrue = allReadTrue(dept, this.state.permissions)
        const isAllWriteTrue = allWriteTrue(dept, this.state.permissions)
        rows.push(
            <TableRow>
                <TableCell><h4><b>All {dept.deptName} fields</b></h4></TableCell>
                <TableCell><Checkbox toggle name={dept.dept} checked={isAllReadTrue} onChange={this.handleToggleDeptCanView}/></TableCell>
                <TableCell><Checkbox toggle name={dept.dept} checked={isAllWriteTrue} onChange={this.handleToggleDeptCanEdit}/></TableCell>
            </TableRow>
        )
        const fields = getFieldsForDept(dept)
        for (var i = 0; i < fields.length; i++) {
            const field = fields[i]
            const permission = getPermissionForField(field.key, this.state.permissions)
            if (permission == null) {
                continue
            }
            rows.push(
                <TableRow key={i}>
                    <TableCell>{field.fieldName}</TableCell>
                    <TableCell><Checkbox toggle name={field.key} checked={permission.read} onChange={this.handleToggleFieldCanView}/></TableCell>
                    <TableCell><Checkbox toggle name={field.key} checked={permission.write} onChange={this.handleToggleFieldCanEdit}/></TableCell>
                </TableRow>
            )
        }
        return rows
    }

    renderDepartments() {
        var arr = []
        for (var i = 0; i < departments.length; i++) {
            const dept = departments[i]
            arr.push(<div>
                <h3>{dept.deptName}</h3>
                <Table striped>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>Field Name</TableHeaderCell>
                        <TableHeaderCell width={2}>Can View</TableHeaderCell>
                        <TableHeaderCell width={2}>Can Edit</TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                {this.renderFields(dept)}
                </TableBody>
              </Table>
                <br />
            </div>)
        }
        return arr
    }

    renderMiscPermissions() {
        return (
            <div>
                <br />
                <Table striped>
                    <TableRow>
                        <TableCell><h4><b>Can Export Client Information Form</b></h4></TableCell>
                        <TableCell width={2}><Checkbox toggle name="" checked={this.state.canExportClientInfoForm} onChange={this.handleToggleCanExportClientInfoForm}/></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><h4><b>Can Filter by FYE</b></h4></TableCell>
                        <TableCell width={2}><Checkbox toggle name="" checked={this.state.canFilterByFYE} onChange={this.handleToggleCanFilterByFYE}/></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><h4><b>Can Filter by Month of Agreement</b></h4></TableCell>
                        <TableCell width={2}><Checkbox toggle name="" checked={this.state.canFilterByMonthOfAgreement} onChange={this.handleToggleCanFilterByMonthOfAgreement}/></TableCell>
                    </TableRow>
                </Table>
                <br />
            </div>
        )
    }
    
    renderForm() {
        var saveButtonString = 'Create Role'
        if (this.props.isEditing) {
            saveButtonString = 'Save Changes'
        }
        const { name, remarks } = this.state
        return (
            <Container>
                <Divider horizontal>        
                    <Header as='h4'><Icon name='info circle'/>Role Details</Header>
                </Divider>
                <Form>
                    <FormField control={Input} label='Name of Role' name='name' value={name} placeholder='' onChange={this.handleChangeField}/>
                    <FormField control={TextArea} rows='2' label='Remarks' name='remarks' value={remarks} placeholder='' onChange={this.handleChangeField}/>
                </Form>
                <br /><br />
                <Divider horizontal>        
                    <Header as='h4'><Icon name='users'/>Staff Members</Header>
                </Divider>      
                <br />
                <Grid divided>
                    <GridColumn width={8}>
                        <h4>Staff Assigned to this Role:</h4>
                        {this.renderLeftUsers()}
                    </GridColumn>
                    <GridColumn width={8}>
                        <h4>Click to Assign Staff:</h4>
                        {this.renderRightUsersWithoutRoles()}
                        <br />
                        <h5>Staff with role already:</h5>
                        {this.renderRightUsersWithRoles()}
                    </GridColumn>
                </Grid>
                <br /><br /><br /><br />
                <Button style={buttonStyle} color='red' onClick={this.handleSaveClicked}>{saveButtonString}</Button><Button onClick={this.handleCancelClicked}>Cancel and Go Back</Button>
                <br /><br />
                <Divider horizontal>        
                    <Header as='h4'><Icon name='question'/>Role Permissions</Header>
                </Divider>
                {this.renderMiscPermissions()}
                {this.renderDepartments()}
                <br />
            </Container>
        )
    }

    render() {
        var breadcrumbString = 'Create New Role'
        var saveButtonString = 'Create Role'
        if (this.props.isEditing) {
            breadcrumbString = this.props.roleToEdit.name
            saveButtonString = 'Save Changes'
        }
        return (
            <Container>
                <br />
                <Breadcrumb size='large'>
                    <BreadcrumbSection onClick={this.props.onBackClicked}>Roles</BreadcrumbSection>
                    <BreadcrumbDivider icon='right chevron' />
                    <BreadcrumbSection active>{breadcrumbString}</BreadcrumbSection>
                </Breadcrumb>
                <br /><br /><br />
                {this.renderForm()}
                <br /><br />
                <Button style={buttonStyle} color='red' onClick={this.handleSaveClicked}>{saveButtonString}</Button><Button onClick={this.handleCancelClicked}>Cancel and Go Back</Button>
                <br /><br /><br /><br /><br /><br /><br /><br />
            </Container>
        )
    }

    handleSaveClicked() {
        var { name, assignedUsers, permissions, canExportClientInfoForm, canFilterByFYE, canFilterByMonthOfAgreement, remarks } = this.state
        if (canExportClientInfoForm == null) {
            canExportClientInfoForm = false
        }
        if (canFilterByFYE == null) {
            canFilterByFYE = false
        }
        if (canFilterByMonthOfAgreement == null) {
            canFilterByMonthOfAgreement = false
        }
        if (name === "" || name === null || !name) {
            alert('Please set a name for this Role')
            return
        }
        var users = []
        for (let user of assignedUsers) {
            let dict = { id: user._id, name: user.name }
            users.push(dict)
        }
        const dict = { name, users: users, permissions, canExportClientInfoForm, canFilterByFYE, canFilterByMonthOfAgreement, remarks }
        if (this.props.isEditing) {
            dict['_id'] = this.props.roleToEdit._id
            this.props.editRole(dict, (success,) => {
                if (success) {
                    this.callAPIAddUsersInRole(true, this.props.roleToEdit)
                } else {
                    alert('Failure editing role, please try again')
                }
            })
        } else {
            this.props.createRole(dict, (success, role) => {
                if (success) {
                    this.callAPIAddUsersInRole(false, role)
                } else {
                    alert('Failure creating role, please try again')
                }
            })
        }
    }

    callAPIAddUsersInRole(isEditingRole, role) {
        let dispatchGroup = new DispatchGroup()
        for (var user of this.state.assignedUsers) {
            const { _id, createdBy, createdAt, name, email, password, remarks } = user
            const roleDict = { name: role.name, id: role._id }
            const dict = { _id, createdBy, createdAt, name, email, role: roleDict, password, remarks }
            let token = dispatchGroup.enter()
            this.props.editUser(dict, (success) => {
                if (success) {
                    dispatchGroup.leave(token)
                } else {
                    alert('Failure editing user, please try again')
                }
            })
        }
        if (isEditingRole) { // handle the unassigned too
            console.log('unassigned users:')
            console.log(this.state.unassignedUsers)
            for (var unassignedUser of this.state.unassignedUsers) {
                const { _id, createdBy, createdAt, name, email, password, remarks } = unassignedUser
                const role = null
                const dict = { _id, createdBy, createdAt, name, email, role, password, remarks }
                let token = dispatchGroup.enter()
                this.props.editUser(dict, (success) => {
                    if (success) {
                        dispatchGroup.leave(token)
                    } else {
                        alert('Failure editing user, please try again')
                    }
                })
            }
        }

        dispatchGroup.notify(() => { 
            if (isEditingRole)
                this.props.onEditSuccess() 
            else
                this.props.onCreateSuccess()
        })
    }

    handleCancelClicked() {
        this.props.onCancel()
    }
}

function mapStateToProps({ user, users }) {
    return { user, users }
}


function inflatePermissions() {
    var arr = []
    for (var i = 0; i < fields.length; i++) {
        const field = fields[i]
        let permission = {
            key: field.key,
            read: false,
            write: false
        }  
        arr.push(permission)
    }
    return arr
}

function getFieldsForDept(dept) {
    var arr = []
    for (var i = 0; i < fields.length; i++) {
        const field = fields[i]
        if (field.department === dept.dept) {
            arr.push(field)
        }
    }
    return arr
}

function getPermissionForField(key, permissions) {
    if (permissions == null) {
        return {}
    }
    for (var i = 0; i < permissions.length; i++) {
        const permission = permissions[i]
        if (key === permission.key) {
            return permission
        }
    }
}

function setWritePermission(key, checked, permissions) {
    var newPermissions = permissions
    for (var i = 0; i < permissions.length; i++) {
        const permission = permissions[i]
        if (key === permission.key) {
            newPermissions[i].write = checked
            return newPermissions
        }
    }
}

function setReadPermission(key, checked, permissions) {
    var newPermissions = permissions
    for (var i = 0; i < permissions.length; i++) {
        const permission = permissions[i]
        if (key === permission.key) {
            newPermissions[i].read = checked
            return newPermissions
        }
    }
}

function getKeysFromDept(dept) {
    var keys = []
    for (var i = 0; i < fields.length; i++) {
        const field = fields[i]
        if (field.department === dept) {
            keys.push(field.key)
        }
    }
    return keys
}

function setWritePermissionDept(dept, checked, permissions) {
    var newPermissions = permissions
    let keys = getKeysFromDept(dept)
    for (var y = 0; y < newPermissions.length; y++) {
        const permission = permissions[y]
        for (var x = 0; x < keys.length; x++) {
            if (permission.key === keys[x]) {
                newPermissions[y].write = checked
                break
            }
        }
    }
    return newPermissions
}

function setReadPermissionDept(dept, checked, permissions) {
    var newPermissions = permissions
    var keys = []
    for (var i = 0; i < fields.length; i++) {
        const field = fields[i]
        if (field.department === dept) {
            keys.push(field.key)
        }
    }
    for (var y = 0; y < newPermissions.length; y++) {
        const permission = permissions[y]
        for (var x = 0; x < keys.length; x++) {
            if (permission.key === keys[x]) {
                newPermissions[y].read = checked
                break
            }
        }
    }
    return newPermissions
}

function allReadTrue(dept, permissions) {
    if (permissions == null) {
        return false
    }
    let keys = getKeysFromDept(dept.dept)
    for (var i = 0; i < permissions.length; i++) {
        const permission = permissions[i]
        for (var x = 0; x < keys.length; x++) {
            if (permission.key === keys[x] && permission.read === false) {
                return false
            }
        }
    } 
    return true
}

function allWriteTrue(dept, permissions) {
    if (permissions == null) {
        return false
    }
    let keys = getKeysFromDept(dept.dept)
    for (var i = 0; i < permissions.length; i++) {
        const permission = permissions[i]
        for (var x = 0; x < keys.length; x++) {
            if (permission.key === keys[x] && permission.write === false) {
                return false
            }
        }
    } 
    return true
}

var DispatchGroup = (function() {
    var nextId = 0

    function DispatchGroup() {
        var id = ++nextId
        var tokens = new Set()
        var onCompleted = null

        function checkCompleted() {
            if(!tokens.size) {
                if(onCompleted) {
                    onCompleted()
                    console.log('group ' + id + ' completed')
                }
            }
        }

        // the only requirement for this is that it's unique during the group's cycle
        function nextToken() {
            return Date.now() + Math.random()
        }

        this.enter = function () {
            let token = nextToken()
            tokens.add(token)
            console.log('group ' + id + ' enter ' + token)
            return token
        }

        this.leave = function (token) {
            if(!token) throw new Error("'token' must be the value earlier returned by '.enter()'")
            tokens.delete(token)
            console.log('group ' + id + ' leave '+token)
            checkCompleted()
        }

        this.notify = function (whenCompleted) {
            if(!whenCompleted) throw new Error("'whenCompleted' must be defined")
            onCompleted = whenCompleted
            checkCompleted()
        }
    }

    return DispatchGroup;
})()

export default connect(mapStateToProps, actions)(NewRole)