import React, { useState, useEffect } from 'react';
import './Testimonials.css';
import { Image, Container, Button, Grid, GridRow, GridColumn } from 'semantic-ui-react'

const Carousell = () => {
  const [visibleDiv, setVisibleDiv] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setVisibleDiv((prevDiv) => (prevDiv === 0 ? 1 : 0));
    }, 6500);

    return () => clearInterval(interval);
  }, []);

  return (
    <Container className='containerT'>
        <div className={`fadeT ${visibleDiv === 0 ? 'showT' : ''}`}>
            <Grid>
                <GridRow>
                    <GridColumn width={4}>
                        <Image fluid src='/testimonial-1.jpg' style={{ height: '21em', width: '17em', objectFit: 'cover' }}/>
                    </GridColumn>
                    <GridColumn width={12}>
                        <div style={{ backgroundColor: '#F7F7F7', height: '20em', width: '70em', marginTop: '0.5em', textAlign: 'left' }}>
                            <h2 style={{ marginLeft: '1em', paddingTop: '1em' }}>The Makeover Inc Pte Ltd</h2>
                            <h4 style={{ marginLeft: '1.55em', paddingTop: '0em', fontSize: '1.2em', fontWeight: '200' }}>
                                We have started the business relationship with FMD since 20 years ago. <br /><br />
                                Our confidence over the years has been built by the trustable team.<br /><br />
                                Being professional, friendly and prompt in service, the team is able to make happy client like us.<br /><br />
                                Brenda Goh<br />Director
                            </h4>
                        </div>
                    </GridColumn>
                </GridRow>
            </Grid>
        </div>
        <div className={`fadeT ${visibleDiv === 1 ? 'showT' : ''}`}>
            <Grid>
                <GridRow>
                    <GridColumn width={4}>
                    <Image fluid src='/testimonial-2.jpg' style={{ height: '21em', width: '17em', objectFit: 'cover' }}/>
                    </GridColumn>
                    <GridColumn width={12}>
                    <div style={{ backgroundColor: '#F7F7F7', height: '17em', width: '70em', marginTop: '2em', textAlign: 'left' }}>
                    <h2 style={{ marginLeft: '1em', paddingTop: '1em' }}>Hydro Dynamic Engineering Pte Ltd</h2>
                    <h4 style={{ marginLeft: '1.55em', paddingTop: '0em', fontSize: '1.2em', fontWeight: '200' }}>
                        IRAS and ACRA are two regulatory agencies that no company can afford to neglect. <br /><br />
                        Every year we have FMD to remind and guide us on the statutory requirements and compliance.<br /><br />
                        Patrick Tan<br />CEO
                    </h4>
                    </div>
                    </GridColumn>
                </GridRow>
            </Grid>
        </div>
    </Container>
  )
}

export default Carousell;