import { Document, Paragraph, TextRun, Table, TableRow, TableCell, HeightRule, WidthType, VerticalAlign, AlignmentType } from "docx"

const currentInfoTable = (info) => new Table({
    width: {
        size: 10000,
        type: WidthType.DXA
    },
    rows: [
        new TableRow({
            height: { value: 300, rule: HeightRule.EXACT },
            children: [
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    width: { size: 25, type: WidthType.PERCENTAGE },
                    children: [new Paragraph("Name of Company")],
                }),
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    width: { size: 75, type: WidthType.PERCENTAGE },
                    children: [new Paragraph(info.companyName)],
                }),
            ],
        }),
        new TableRow({
            children: [
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    children: [new Paragraph("Registration No.")],
                }),
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    children: [new Paragraph(info.roc)],
                }),
            ],
        }),
        new TableRow({
            children: [
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    children: [new Paragraph("Mailing Address")],
                }),
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    children: [new Paragraph(info.mailingAddress)],
                }),
            ],
        }),
        new TableRow({
            children: [
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    children: [new Paragraph("Registered Address")],
                }),
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    children: [new Paragraph(info.registeredAddress)],
                }),
            ],
        }),
        new TableRow({
            children: [
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    children: [new Paragraph("Phone (Primary)")],
                }),
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    children: [new Paragraph(info.phone1)],
                }),
            ],
        }),
        new TableRow({
            children: [
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    children: [new Paragraph("Phone (Secondary)")],
                }),
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    children: [new Paragraph(info.phone2)],
                }),
            ],
        }),
        new TableRow({
            children: [
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    children: [new Paragraph("Fax No.")],
                }),
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    children: [new Paragraph(info.fax)],
                }),
            ],
        }),
    ],
})

const contactPersonTable = (index, info) => {
    var name = ""
    var phone = ""
    var email = ""
    var remarks = ""

    if (index === "1") {
        name = info.auth1Name
        phone = info.auth1Mobile
        email = info.auth1Email
        remarks = info.auth1Remarks
    } else if (index === "2") {
        name = info.auth2Name
        phone = info.auth2Mobile
        email = info.auth2Email
        remarks = info.auth2Remarks
    } else if (index === "3") {
        name = info.auth3Name
        phone = info.auth3Mobile
        email = info.auth3Email
        remarks = info.auth3Remarks
    } else if (index === "4") {
        name = info.auth4Name
        phone = info.auth4Mobile
        email = info.auth4Email
        remarks = info.auth4Remarks
    }
    if (name == null) name = ""
    if (phone == null) phone = ""
    if (email == null) email = ""
    if (remarks == null) remarks = ""
    return new Table({
        width: {
            size: 10000,
            type: WidthType.DXA
        },
        rows: [
            new TableRow({
                height: { value: 300, rule: HeightRule.EXACT },
                children: [
                    new TableCell({
                        margins: { top: 60, right: 120, bottom: 40, left: 240 },
                        width: { size: 6, type: WidthType.PERCENTAGE },
                        rowSpan: 4,
                        children: [new Paragraph(index)],
                    }),
                    new TableCell({
                        margins: { top: 60, right: 120, bottom: 40, left: 120 },
                        width: { size: 20, type: WidthType.PERCENTAGE },
                        children: [new Paragraph("Name")],
                    }),
                    new TableCell({
                        margins: { top: 60, right: 120, bottom: 40, left: 120 },
                        width: { size: 38, type: WidthType.PERCENTAGE },
                        children: [new Paragraph(name)],
                    }),
                    new TableCell({
                        margins: { top: 60, right: 120, bottom: 40, left: 120 },
                        width: { size: 38, type: WidthType.PERCENTAGE },
                        rowSpan: 4,
                        children: [new Paragraph(`Remarks: ${remarks}`)],
                    }),
                ],
            }),
            new TableRow({
                height: { value: 300, rule: HeightRule.EXACT },
                children: [
                    new TableCell({
                        margins: { top: 60, right: 120, bottom: 40, left: 120 },
                        children: [new Paragraph("Tel / Mobile No.")],
                    }),
                    new TableCell({
                        margins: { top: 60, right: 120, bottom: 40, left: 120 },
                        children: [new Paragraph(phone)],
                    }),
                ],
            }),
            new TableRow({
                height: { value: 300, rule: HeightRule.EXACT },
                children: [
                    new TableCell({
                        margins: { top: 60, right: 120, bottom: 40, left: 120 },
                        children: [new Paragraph("Email")],
                    }),
                    new TableCell({
                        margins: { top: 60, right: 120, bottom: 40, left: 120 },
                        children: [new Paragraph(email)],
                    }),
                ],
            }),
        ],
    })
}

const revisedInfoTable = new Table({
    width: {
        size: 10000,
        type: WidthType.DXA
    },
    rows: [
        new TableRow({
            height: { value: 300, rule: HeightRule.EXACT },
            children: [
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    width: { size: 25, type: WidthType.PERCENTAGE },
                    children: [new Paragraph("Name of Company")],
                }),
                new TableCell({
                    width: { size: 75, type: WidthType.PERCENTAGE },
                    children: [],
                }),
            ],
        }),
        new TableRow({
            children: [
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    children: [new Paragraph("Registration No.")],
                }),
                new TableCell({
                    children: [],
                }),
            ],
        }),
        new TableRow({
            children: [
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    children: [new Paragraph("Mailing Address")],
                }),
                new TableCell({
                    children: [],
                }),
            ],
        }),
        new TableRow({
            children: [
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    children: [new Paragraph("Registered Address")],
                }),
                new TableCell({
                    children: [],
                }),
            ],
        }),
        new TableRow({
            children: [
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    children: [new Paragraph("Phone (Primary)")],
                }),
                new TableCell({
                    children: [],
                }),
            ],
        }),
        new TableRow({
            children: [
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    children: [new Paragraph("Phone (Secondary)")],
                }),
                new TableCell({
                    children: [],
                }),
            ],
        }),
        new TableRow({
            children: [
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    children: [new Paragraph("Fax No.")],
                }),
                new TableCell({
                    children: [],
                }),
            ],
        }),
    ],
})

const pleaseTickRevisionTable = new Table({
    width: {
        size: 10000,
        type: WidthType.DXA
    },
    rows: [
        new TableRow({
            height: { value: 300, rule: HeightRule.EXACT },
            children: [
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    verticalAlign: VerticalAlign.CENTER,
                    width: { size: 25, type: WidthType.PERCENTAGE },
                    rowSpan: 2,
                    children: [new Paragraph("Please tick revision for")],
                }),
                new TableCell({
                    margins: { top: 10, right: 120, bottom: 40, left: 120 },
                    width: { size: 70, type: WidthType.PERCENTAGE },
                    children: [new Paragraph("a)  REPLACE current contact person(s)")],
                }),
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    width: { size: 5, type: WidthType.PERCENTAGE },
                    children: [],
                }),
            ],
        }),
        new TableRow({
            height: { value: 300, rule: HeightRule.EXACT },
            children: [
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    width: { size: 70, type: WidthType.PERCENTAGE },
                    children: [new Paragraph("b)  ADD below without deleting current contact person(s)")],
                }),
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    width: { size: 5, type: WidthType.PERCENTAGE },
                    children: [],
                }),
            ],
        }),
    ],
})

const revisedContactPersonTable = (index) => new Table({
    width: {
        size: 10000,
        type: WidthType.DXA
    },
    rows: [
        new TableRow({
            height: { value: 300, rule: HeightRule.EXACT },
            children: [
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 240 },
                    width: { size: 6, type: WidthType.PERCENTAGE },
                    rowSpan: 4,
                    children: [new Paragraph(index)],
                }),
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    width: { size: 20, type: WidthType.PERCENTAGE },
                    children: [new Paragraph("Name")],
                }),
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    width: { size: 38, type: WidthType.PERCENTAGE },
                    children: [],
                }),
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    width: { size: 38, type: WidthType.PERCENTAGE },
                    rowSpan: 4,
                    children: [new Paragraph("Remarks")],
                }),
            ],
        }),
        new TableRow({
            height: { value: 300, rule: HeightRule.EXACT },
            children: [
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    children: [new Paragraph("Tel / Mobile No.")],
                }),
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    children: [],
                }),
            ],
        }),
        new TableRow({
            height: { value: 300, rule: HeightRule.EXACT },
            children: [
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    children: [new Paragraph("Email")],
                }),
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    children: [],
                }),
            ],
        }),
    ],
})


const directorTable = new Table({
    width: {
        size: 10000,
        type: WidthType.DXA
    },
    rows: [
        new TableRow({
            height: { value: 300, rule: HeightRule.EXACT },
            children: [
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    width: { size: 10, type: WidthType.PERCENTAGE },
                    children: [new Paragraph("Name")],
                }),
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    width: { size: 55, type: WidthType.PERCENTAGE },
                    children: [],
                }),
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 1100 },
                    width: { size: 35, type: WidthType.PERCENTAGE },
                    rowSpan: 4,
                    verticalAlign: VerticalAlign.BOTTOM,
                    children: [new Paragraph("Signature / Date")],
                }),
            ],
        }),
        new TableRow({
            height: { value: 300, rule: HeightRule.EXACT },
            children: [
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    width: { size: 10, type: WidthType.PERCENTAGE },
                    children: [new Paragraph("Mobile")],
                }),
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    width: { size: 55, type: WidthType.PERCENTAGE },
                    children: [],
                }),
            ],
        }),
        new TableRow({
            height: { value: 300, rule: HeightRule.EXACT },
            children: [
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    width: { size: 10, type: WidthType.PERCENTAGE },
                    children: [new Paragraph("Email")],
                }),
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    width: { size: 55, type: WidthType.PERCENTAGE },
                    children: [],
                }),
            ],
        }),
        new TableRow({
            height: { value: 300, rule: HeightRule.EXACT },
            children: [
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    width: { size: 10, type: WidthType.PERCENTAGE },
                    children: [new Paragraph("Address")],
                }),
                new TableCell({
                    margins: { top: 60, right: 120, bottom: 40, left: 120 },
                    width: { size: 55, type: WidthType.PERCENTAGE },
                    children: [],
                }),
            ],
        }),
    ],
})


export const doc = (info) => new Document({
    sections: [
        {
            properties: {
                page: { margin: {top: 537, right: 809, bottom: 537, left: 809 } },
            },
            children: [
                new Paragraph({
                    children: [
                        new TextRun({ text: "CURRENT CLIENT INFORMATION", size: 24, bold: true })
                    ],
                    alignment: 'center',
                    spacing: { after: 200 },
                }),
                currentInfoTable(info),
                new Paragraph({
                    spacing: { before: 220 },
                    children: [
                        new TextRun({ text: "Authorised Contact Person", size: 20, bold: true })
                    ]
                }),
                new Paragraph({
                    spacing: { before: 30 },
                    children: [
                        new TextRun({ text: "The authorized contact person will act as the company’s representative for communication with FMD. If there are any specific conditions related to this role, please mention them in the remark box. Otherwise, if no remarks are given, FMD will at own discretion choose ANY of the contact persons for liaison purposes.", size: 18 })
                    ]
                }),
                new Paragraph({ spacing: { before: 40 }, children: [] }),
                contactPersonTable("1", info),
                contactPersonTable("2", info),
                contactPersonTable("3", info),
                contactPersonTable("4", info),
                new Paragraph({
                    children: [
                        new TextRun({ text: "REVISED INFORMATION", size: 24, bold: true })
                    ],
                    alignment: 'center',
                    spacing: { before: 350 },
                }),
                new Paragraph({
                    spacing: { before: 220, after: 120 },
                    children: [
                        new TextRun({ text: "Please fill ", size: 18 }),
                        new TextRun({ text: "ONLY", size: 18, bold: true }),
                        new TextRun({ text: " when there are changes in above detail.", size: 18 })
                    ]
                }),
                revisedInfoTable,
                new Paragraph({
                    spacing: { before: 220, after: 80 },
                    children: [
                        new TextRun({ text: "Authorised Contact Person", size: 20, bold: true })
                    ]
                }),
                pleaseTickRevisionTable,
                new Paragraph({ spacing: { before: 80 }, children: [] }),
                revisedContactPersonTable("1"),
                revisedContactPersonTable("2"),
                revisedContactPersonTable("3"),
                revisedContactPersonTable("4"),
                new Paragraph({
                    children: [
                        new TextRun({ text: "DECLARATION BY DIRECTORS", size: 24, bold: true })
                    ],
                    alignment: 'center',
                    spacing: { before: 500 },
                }),
                new Paragraph({
                    spacing: { before: 300 },
                    alignment: AlignmentType.JUSTIFIED,
                    children: [
                        new TextRun({ text: "I / We majority of the board of directors, hereby represent the board of directors to confirm that the information given above is true and correct.", size: 18 })
                    ]
                }),
                new Paragraph({
                    spacing: { before: 300 },
                    alignment: AlignmentType.JUSTIFIED,
                    children: [
                        new TextRun({ text: "I / We, hereby authorize FMD Management Consultants Pte Ltd / LW Ong & Associates LLP / FMD 2008 Pte. Ltd. / FMD Pte. Ltd (jointly indicated as “FMD” in this form) to liaise with the above contact person as per instruction indicated on this form.", size: 18 })
                    ]
                }),
                new Paragraph({
                    spacing: { before: 300 },
                    alignment: AlignmentType.JUSTIFIED,
                    children: [
                        new TextRun({ text: "I / We will keep you inform in writing for any changes in the information/instruction given above.", size: 18 })
                    ]
                }),
                new Paragraph({
                    spacing: { before: 300, after: 400 },
                    alignment: AlignmentType.JUSTIFIED,
                    children: [
                        new TextRun({ text: "I / We hereby agree that FMD, its officer, staff, agent and assignee may collect, use and disclose the personal data, as provided in this form, for performing the services that the Company has engaged or will be engaging or to the extent necessary to comply with any laws, regulations, rules and guidelines, and approve that FMD may transfer such personal data to FMD’s related group of companies, third party service providers whether located in Singapore or elsewhere for the purposes to carry out the services and/or as required by law to disclose.", size: 18 })
                    ]
                }),
                directorTable,
                new Paragraph({ spacing: { before: 200 }, children: [] }),
                directorTable,
                new Paragraph({ spacing: { before: 200 }, children: [] }),
                directorTable,
                new Paragraph({ spacing: { before: 200 }, children: [] }),
                directorTable,
                new Paragraph({ spacing: { before: 200 }, children: [] }),
                directorTable,
                new Paragraph({ spacing: { before: 600 }, children: [] }),
                directorTable,
                new Paragraph({ spacing: { before: 200 }, children: [] }),
                directorTable,
                new Paragraph({ spacing: { before: 650 }, children: [
                    new TextRun({ text: "Date received by FMD:", size: 22 })
                ] }),
            ],
        },
    ],
})
                // 1mm = 56.5476
                // top: 0.95cm,
                // right: 1.43cm,
                // bottom: 0.16cm,
                // left: 1.43cm,