import React, { Component } from "react"

import { Container, Breadcrumb, BreadcrumbSection, BreadcrumbDivider, Message, Form, FormGroup, FormField, Input, TextArea, Button } from 'semantic-ui-react'

class ViewUser extends Component {
    state = { user: {} }

    componentDidMount() {
        this.setState({ user: this.props.userToView })
    }
  
    handleChangeField = e => {
        if (e.target.name === 'role' && this.state.user.role == null) {
            var newUser = this.state.user
            newUser[e.target.name] = ""
            this.setState({ user: newUser })
            return
        }
        if (this.state.user[e.target.name] == null) { // make sure that empty fields can't be edited
            var user = this.state.user
            user[e.target.name] = ""
            this.setState({ user: user })
        }
    }
    
    renderForm() {
        const { name, email, role, password, remarks } = this.state.user
        var roleName = ""
        if (Object.keys(this.state.user).length === 0) {
            return
        }
        if (!!role) {
            roleName = role.name
        }
        return (
            <Container>
                <Form>
                    <FormGroup widths={"equal"}>
                        <FormField control={Input} label='Name of Staff' name='name' value={name} placeholder='' onChange={this.handleChangeField}/>
                        <FormField control={Input} label='Role' name='role' value={roleName} placeholder='' onChange={this.handleChangeField}/>
                    </FormGroup>    
                    <FormGroup widths={"equal"}>
                        <FormField control={Input} label='Email' name='email' value={email} placeholder='' onChange={this.handleChangeField}/>
                        <FormField control={Input} label='Password' type="password" name='password' value={password} placeholder='' onChange={this.handleChangeField}/>
                    </FormGroup>
                    <FormField control={TextArea} rows='2' label='Remarks' name='remarks' value={remarks} placeholder='' onChange={this.handleChangeField}/>
                </Form>
            </Container>
        )
    }

    render() {
        return (
            <Container>
                <br />
                <Breadcrumb size='large'>
                    <BreadcrumbSection link onClick={this.props.onBackClicked}>My Staff</BreadcrumbSection>
                    <BreadcrumbDivider icon='right chevron' />
                    <BreadcrumbSection active>{this.state.user.name}</BreadcrumbSection>
                </Breadcrumb>
                <br /><br />
                <Message info header='This page is view-only.' content='Its contents are uneditable.'/>
                <br />
                {this.renderForm()}
                <br /><br />
                <Button onClick={this.props.onBackClicked}>Back to My Staff Page</Button>
                <br /><br /><br /><br /><br /><br /><br /><br />
            </Container>
        )
    }
}

export default ViewUser