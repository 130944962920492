import React, { Component } from "react"
import { fields } from "../../../../fields"

import { Container, Form, FormGroup, FormField, Input, Radio, TextArea } from 'semantic-ui-react'

const departmentName = 'Operations'

class Operations extends Component {
    state = {}

    componentDidMount() {
        var savedDict = {}
        if (this.props.savedDict) {
            savedDict = this.props.savedDict
        } else {
            return
        }
        this.setState({
            awpDrawerNo: savedDict.awpDrawerNo,
            ctDrawerNo: savedDict.ctDrawerNo,
            extCorpSecName: savedDict.extCorpSecName,
            extCorpSecAddress: savedDict.extCorpSecAddress,
            extCorpSecEmail: savedDict.extCorpSecEmail,
            extSecGroup: savedDict.extSecGroup,
            extSecYearEnd: savedDict.extSecYearEnd,
            reportToTourismBoard: savedDict.reportToTourismBoard,
            dateOfAppointment: savedDict.dateOfAppointment,
            remarks: savedDict.remarks,
            others: savedDict.others,
        })
    }

    handleChangeField = e => {
        if (this.props.viewOnly) {
            if (this.state[e.target.name] == null) {
                this.setState({ [e.target.name]: ""})
            }
            return
        }
        const { name, value } = e.target
        if (!getWritePermissionForField(name, this.props.permissions) && this.state[e.target.name] == null) {
            this.setState({ [e.target.name]: ""})
            return
        }   
        else if (!getWritePermissionForField(name, this.props.permissions)) return
        this.setState({ [name]: value }, () =>
            this.props.onStateChange(this.state)
        )
    }
    handleChangeReportToTourismBoard = (e, { value }) => { 
        if (this.props.viewOnly) return
        if (!getWritePermissionForField('reportToTourismBoard', this.props.permissions)) return
        this.setState({ reportToTourismBoard: value }, () => 
            this.props.onStateChange(this.state)
        )
    }

    getFieldStyle(name) {
        if (!getReadPermissionForField(name, this.props.permissions)) {
            return { visibility: 'hidden' }
        }
        return { visibility: 'visible' }
    }

    render() {
        const { awpDrawerNo, ctDrawerNo, extCorpSecName, extCorpSecEmail, extCorpSecAddress, extSecGroup, extSecYearEnd, reportToTourismBoard, dateOfAppointment, remarks, others } = this.state
        return (
            <Container>
                <Form>
                    <FormGroup widths={"equal"}>
                        <FormField style={this.getFieldStyle('awpDrawerNo')} control={Input} label='AWP Drawer No' name='awpDrawerNo' value={awpDrawerNo} placeholder='' onChange={this.handleChangeField}/>
                        <FormField style={this.getFieldStyle('ctDrawerNo')} control={Input} label='C&T Drawer No' name='ctDrawerNo' value={ctDrawerNo} placeholder='' onChange={this.handleChangeField}/>
                    </FormGroup>
                    <FormGroup widths={"equal"}>
                        <FormField style={this.getFieldStyle('extCorpSecName')} control={Input} label='Name of External Corp Sec' name='extCorpSecName' value={extCorpSecName} placeholder='' onChange={this.handleChangeField}/>
                        <FormField style={this.getFieldStyle('extCorpSecEmail')} control={Input} label='Email of External Corp Sec' name='extCorpSecEmail' value={extCorpSecEmail} placeholder='' onChange={this.handleChangeField}/>
                    </FormGroup>
                    <FormField style={this.getFieldStyle('extCorpSecAddress')} control={TextArea} rows='3' label='Address of External Corp Sec' name='extCorpSecAddress' value={extCorpSecAddress} placeholder='' onChange={this.handleChangeField}/>
                    <FormGroup widths={"equal"}>
                        <FormField style={this.getFieldStyle('extSecGroup')} control={Input} label='Group (External Sec)' name='extSecGroup' value={extSecGroup} placeholder='' onChange={this.handleChangeField}/>
                        <FormField style={this.getFieldStyle('extSecYearEnd')} control={Input} label='Year End (External Sec)' name='extSecYearEnd' value={extSecYearEnd} placeholder='' onChange={this.handleChangeField}/>
                    </FormGroup>
                    <FormField style={this.getFieldStyle('dateOfAppointment')} inline control={Input} label='Date of Appointment' type='date' name='dateOfAppointment' value={dateOfAppointment} placeholder='' onChange={this.handleChangeField}/>
                    <FormGroup style={this.getFieldStyle('reportToTourismBoard')} inline>
                        <label>Report to Tourism Board?</label>
                        <FormField control={Radio} label='Yes' value='yes' checked={reportToTourismBoard === 'yes'} onChange={this.handleChangeReportToTourismBoard}/>
                        <FormField control={Radio} label='No' value='no' checked={reportToTourismBoard === 'no'} onChange={this.handleChangeReportToTourismBoard}/>
                    </FormGroup>
                    <FormField style={this.getFieldStyle('remarks')} control={TextArea} rows='2' label='Remarks' name='remarks' value={remarks} placeholder='' onChange={this.handleChangeField}/>
                    <FormField style={this.getFieldStyle('others')} control={Input} label='Others' name='others' value={others} placeholder='' onChange={this.handleChangeField}/>
                </Form>
            </Container>
        )
    }
}

function getWritePermissionForField(fieldName, permissions) {
    var key = ""
    for (let field of fields) {
        if (field.field === fieldName && field.departmentName === departmentName) {
            key = field.key
        }
    }
    for (let permission of permissions) {
        if (permission.key === key) {
            if (permission.write === false) {
            }
            return permission.write
        }
    }
}

function getReadPermissionForField(fieldName, permissions) {
    var key = ""
    for (let field of fields) {
        if (field.field === fieldName && field.departmentName === departmentName) {
            key = field.key
        }
    }
    for (let permission of permissions) {
        if (permission.key === key) {
            return permission.read
        }
    }
}

export default Operations