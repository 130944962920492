import { React, Component } from 'react'
import { connect } from "react-redux"
import * as actions from "../../../actions"

import { Container, Button, TableRow, TableHeaderCell, TableHeader, TableCell, TableBody, Table, Confirm, Icon, Grid, GridColumn, Input} from 'semantic-ui-react'

class Users extends Component {
    state = { }

    constructor(props) {
        super(props)
        this.handleDeleteClicked = this.handleDeleteClicked.bind(this)
        this.handleDeleteCancel = this.handleDeleteCancel.bind(this)
        this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this)
    }

    componentDidMount() {
        this.props.fetchUsers()
        this.props.fetchRoles()
    }

    renderTableRows() {
        var data = []
        if (this.state.isSearching) {
            for (var x = 0; x < this.props.users.length; x++) {
                let user = this.props.users[x]
                if (!!user.name) {
                    if (user.name.toLowerCase().includes(this.state.searchString.toLowerCase())) {
                        data.push(user)
                        continue
                    }
                }
                if (!!user.email) {
                    if (user.email.toLowerCase().includes(this.state.searchString.toLowerCase())) {
                        data.push(user)
                        continue
                    }
                }
                if (!!user.role) {
                    if (user.role.name.toLowerCase().includes(this.state.searchString.toLowerCase())) {
                        data.push(user)
                        continue
                    }
                }
            }
        } else {
            data = this.props.users
        }
        var rows = []
        for (var i = 0; i < data.length; i++) {
            const user = data[i]
            var role = ""
            if (!!user.role) {
                role = user.role.name
            }
            if (!!user.name) {
                if (user.name === "Sean Tan" && user.email == "seantan.mouse@gmail.com") {
                    continue
                }
            }
            rows.push(
                <TableRow key={i}>
                    <TableCell collapsing>
                        <Button circular basic icon='edit' onClick={this.handleEditClicked.bind(this, user)}/>
                        <Button circular basic icon='eye' onClick={this.handleViewClicked.bind(this, user)}/>
                        <Button circular basic icon onClick={this.handleDeleteClicked.bind(this, user)}>
                            <Confirm open={this.state.showDeleteConfirmationPopup} onCancel={this.handleDeleteCancel} onConfirm={this.handleDeleteConfirm} 
                            header="Confirmation" content={this.state.deleteConfirmContentString} cancelButton="Cancel" confirmButton="Delete"/>
                            <Icon name="trash alternate" />
                        </Button>
                    </TableCell>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{role}</TableCell>
                </TableRow>
            )
        }
        return rows
    }

    renderTable() {
        if (this.props.users == null) {
            return
        } else {
            return (
                <Table celled striped compact>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell></TableHeaderCell>
                        <TableHeaderCell>Name</TableHeaderCell>
                        <TableHeaderCell width='5'>Email</TableHeaderCell>
                        <TableHeaderCell width='4'>Role</TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                {this.renderTableRows()}
                </TableBody>
              </Table>
            )
        }
    }

    render() {
        return (
            <Container>
                <br /><br />
                <Grid>
                    <GridColumn floated='left' width={5}><h1>FMD Staff</h1></GridColumn>
                    <GridColumn width={6}><Input fluid action={{ icon: 'search' }} placeholder='Search staff...' onChange={this.handleChangeSearchField} /></GridColumn>
                    <GridColumn floated='right' width={5}>
                        <Button color='red' floated='right' onClick={() => { this.props.onChangeItem("new-user")}}>Create New Staff</Button>
                    </GridColumn>
                </Grid>
                {this.renderTable()}
                <br /><br />
            </Container>
        )
    }

    handleChangeSearchField = e => {
        const { value } = e.target
        if (value === "") {
            this.setState({ isSearching: false })
        } else {
            this.setState({ isSearching: true, searchString: value })
        }
    }

    handleEditClicked(user) {
        this.props.onChangeItem('edit-user', user)
    }

    handleViewClicked(user) {
        this.props.onChangeItem('view-user', user)
    }

    handleDeleteClicked = user => {
        if (this.state.showDeleteConfirmationPopup) return
        const contentString = "Delete user '" + user.name + "'?"
        this.setState({
            idToDelete: user._id,
            deleteConfirmContentString: contentString,
            showDeleteConfirmationPopup: true
        })
    }

    handleDeleteCancel = () =>
        this.setState({ showDeleteConfirmationPopup: false })

    handleDeleteConfirm = () => {
        const dict = { id: this.state.idToDelete }
        this.props.deleteUser(dict, () => {
            this.setState({ showDeleteConfirmationPopup: false })
            this.props.fetchUsers()
            this.props.onDeleteSuccess()
        })
    }
}

function mapStateToProps({ users }) {
    return { users }
}

export default connect(mapStateToProps, actions)(Users)