import React, { Component } from "react"
import { fields } from "../../../../fields"

import { Container, Divider, Header, Icon, Form, FormGroup, FormField, Input, Radio, Select, TextArea } from 'semantic-ui-react'

const departmentName = 'Client Info'

const options = [
    { key: 'pte-ltd', text: 'Pte Ltd', value: 'Pte Ltd' },
    { key: 'biz', text: 'Business', value: 'Business' },
    { key: 'llp', text: 'LLP', value: 'LLP' },
    { key: 'corp', text: 'Corporation', value: 'Corporation' },
    { key: 'ass-soc', text: 'Association & Society', value: 'Association & Society' },
    { key: 'ind', text: 'Individual', value: 'Individual' },
    { key: 'others', text: 'Others', value: 'Others' },
  ]

class ClientInfo extends Component {
    state = {}

    componentDidMount() {
        var savedDict = {}
        if (this.props.savedDict) {
            savedDict = this.props.savedDict
        } else {
            return
        }
        this.setState({
            clientType: savedDict.clientType,
            companyName: savedDict.companyName,
            roc: savedDict.roc,
            entityType: savedDict.entityType,
            suspendPayment: savedDict.suspendPayment,
            oneContactPoint: savedDict.oneContactPoint,
            group: savedDict.group,
            remarksInfo: savedDict.remarksInfo,
            othersInfo: savedDict.othersInfo,
            registeredAddress: savedDict.registeredAddress,
            mailingAddress: savedDict.mailingAddress,
            phone1: savedDict.phone1,
            phone2: savedDict.phone2,
            fax: savedDict.fax,
            remarksContact: savedDict.remarksContact,
            othersContact: savedDict.othersContact,
            auth1Name: savedDict.auth1Name,
            auth1Position: savedDict.auth1Position,
            auth1Mobile: savedDict.auth1Mobile,
            auth1Email: savedDict.auth1Email,
            auth1Remarks: savedDict.auth1Remarks,
            auth1Others: savedDict.auth1Others,
            auth1OptOutEmail: savedDict.auth1OptOutEmail,
            auth2Name: savedDict.auth2Name,
            auth2Position: savedDict.auth2Position,
            auth2Mobile: savedDict.auth2Mobile,
            auth2Email: savedDict.auth2Email,
            auth2Remarks: savedDict.auth2Remarks,
            auth2Others: savedDict.auth2Others,
            auth2OptOutEmail: savedDict.auth2OptOutEmail,
            auth3Name: savedDict.auth3Name,
            auth3Position: savedDict.auth3Position,
            auth3Mobile: savedDict.auth3Mobile,
            auth3Email: savedDict.auth3Email,
            auth3Remarks: savedDict.auth3Remarks,
            auth3Others: savedDict.auth3Others,
            auth3OptOutEmail: savedDict.auth3OptOutEmail,
            auth4Name: savedDict.auth4Name,
            auth4Position: savedDict.auth4Position,
            auth4Mobile: savedDict.auth4Mobile,
            auth4Email: savedDict.auth4Email,
            auth4Remarks: savedDict.auth4Remarks,
            auth4Others: savedDict.auth4Others,
            auth4OptOutEmail: savedDict.auth4OptOutEmail
        })
    }

    handleChangeField = e => {
        if (this.props.viewOnly) {
            if (this.state[e.target.name] == null) {
                this.setState({ [e.target.name]: ""})
            }
            return
        }
        const { name, value } = e.target
        if (!getWritePermissionForField(name, this.props.permissions) && this.state[e.target.name] == null) {
            this.setState({ [e.target.name]: ""})
            return
        }   
        else if (!getWritePermissionForField(name, this.props.permissions)) return
        this.setState({ [name]: value }, () =>
            this.props.onStateChange(this.state)
        )
    }
    handleChangeEntityDropDown = e => {
        if (this.props.viewOnly) return
        if (!getWritePermissionForField('entityType', this.props.permissions)) return
        this.setState({ entityType: e.target.innerText }, () =>
            this.props.onStateChange(this.state)
        )
    }
    handleChangeClientType = (e, { value }) => {
        if (this.props.viewOnly) return
        if (!getWritePermissionForField('clientType', this.props.permissions)) return
        this.setState({ clientType: value }, () =>
            this.props.onStateChange(this.state)
        )
    }
    handleChangeSuspendPayment = (e, { value }) => { 
        if (this.props.viewOnly) return
        if (!getWritePermissionForField('suspendPayment', this.props.permissions)) return
        this.setState({ suspendPayment: value }, () => 
            this.props.onStateChange(this.state)
        )
    }
    handleChangeOneContactPoint = (e, { value }) => { 
        if (this.props.viewOnly) return
        if (!getWritePermissionForField('oneContactPoint', this.props.permissions)) return
        this.setState({ oneContactPoint: value }, () => 
            this.props.onStateChange(this.state)
        )
    }
    handleChangeOptOutEmail1 = (e, { value }) => { 
        if (this.props.viewOnly) return
        if (!getWritePermissionForField('auth1OptOutEmail', this.props.permissions)) return
        this.setState({ auth1OptOutEmail: value }, () => 
            this.props.onStateChange(this.state)
        )
    }
    handleChangeOptOutEmail2 = (e, { value }) => { 
        if (this.props.viewOnly) return
        if (!getWritePermissionForField('auth2OptOutEmail', this.props.permissions)) return
        this.setState({ auth2OptOutEmail: value }, () => 
            this.props.onStateChange(this.state)
        )
    }
    handleChangeOptOutEmail3 = (e, { value }) => { 
        if (this.props.viewOnly) return
        if (!getWritePermissionForField('auth3OptOutEmail', this.props.permissions)) return
        this.setState({ auth3OptOutEmail: value }, () => 
            this.props.onStateChange(this.state)
        )
    }
    handleChangeOptOutEmail4 = (e, { value }) => { 
        if (this.props.viewOnly) return
        if (!getWritePermissionForField('auth4OptOutEmail', this.props.permissions)) return
        this.setState({ auth4OptOutEmail: value }, () => 
            this.props.onStateChange(this.state)
        )
    }

    getFieldStyle(name) {
        if (!getReadPermissionForField(name, this.props.permissions)) {
            return { visibility: 'hidden' }
        }
        return { visibility: 'visible' }
    }

    render() {
        const { clientType, companyName, roc, entityType, suspendPayment, oneContactPoint, group, remarksInfo, othersInfo, 
            registeredAddress, mailingAddress, phone1, phone2, fax, remarksContact, othersContact,
            auth1Name, auth1Position, auth1Mobile, auth1Email, auth1Remarks, auth1Others, auth1OptOutEmail, 
            auth2Name, auth2Position, auth2Mobile, auth2Email, auth2Remarks, auth2Others, auth2OptOutEmail, 
            auth3Name, auth3Position, auth3Mobile, auth3Email, auth3Remarks, auth3Others, auth3OptOutEmail, 
            auth4Name, auth4Position, auth4Mobile, auth4Email, auth4Remarks, auth4Others, auth4OptOutEmail } = this.state
        return (
            <Container>
                <Divider horizontal>
                    <Header as='h4'>
                        <Icon name='user' />
                        Client Information
                    </Header>
                </Divider>
                <Form>
                    <FormGroup style={this.getFieldStyle('clientType')} inline>
                        <label>Client Type:</label>
                        <FormField control={Radio} label='Client' value='client' checked={clientType === 'client'} onChange={this.handleChangeClientType}/>
                        <FormField control={Radio} label='Prospect' value='prospect' checked={clientType === 'prospect'} onChange={this.handleChangeClientType}/>
                        <FormField control={Radio} label='Others' value='others' checked={clientType === 'others'} onChange={this.handleChangeClientType}/>
                    </FormGroup>
                    <br />
                    <FormGroup>
                        <FormField style={this.getFieldStyle('companyName')} control={Input} label='Company Name' name='companyName' value={companyName} placeholder='Company Name' width={10} onChange={this.handleChangeField}/>
                        <FormField style={this.getFieldStyle('roc')}control={Input} label='ROC' name='roc' value={roc} placeholder='Business Registration No' width={3} onChange={this.handleChangeField}/>
                        <FormField style={this.getFieldStyle('entityType')} control={Select} label='Entity Type' name='entityType' value={entityType} defaultValue={entityType} options={options} placeholder='Select one' width={3} onChange={this.handleChangeEntityDropDown}/>
                    </FormGroup>
                    <br />
                    <FormGroup style={this.getFieldStyle('suspendPayment')} inline>
                        <label>Suspend Payment Status:</label>
                        <FormField control={Radio} label='Yes' value='yes' checked={suspendPayment === 'yes'} onChange={this.handleChangeSuspendPayment}/>
                        <FormField control={Radio} label='No' value='no' checked={suspendPayment === 'no'} onChange={this.handleChangeSuspendPayment}/>
                    </FormGroup>
                    <FormGroup style={this.getFieldStyle('oneContactPoint')} inline>
                        <label>One Contact Point:</label>
                        <FormField control={Radio} label='Yes' value='yes' checked={oneContactPoint === 'yes'} onChange={this.handleChangeOneContactPoint}/>
                        <FormField control={Radio} label='No' value='no' checked={oneContactPoint === 'no'} onChange={this.handleChangeOneContactPoint}/>
                    </FormGroup>
                    <br />
                    <FormField style={this.getFieldStyle('group')} control={Input} label='Group' name='group' value={group} placeholder='Group' onChange={this.handleChangeField}/>
                    <FormField style={this.getFieldStyle('remarksInfo')} control={TextArea} rows='2' label='Remarks' name='remarksInfo' value={remarksInfo} placeholder='' onChange={this.handleChangeField}/>
                    <FormField style={this.getFieldStyle('othersInfo')} control={Input} label='Others' name='othersInfo' value={othersInfo} placeholder='' onChange={this.handleChangeField}/>
                </Form>
                <br /><br />
                <Divider horizontal>
                    <Header as='h4'>
                        <Icon name='phone' />
                        Client Contact Details
                    </Header>
                </Divider>
                <Form>
                    <FormGroup widths={"equal"}>
                        <FormField style={this.getFieldStyle('registeredAddress')} control={TextArea} label='Registered Address' name='registeredAddress' value={registeredAddress} placeholder='Address' onChange={this.handleChangeField}/>
                        <FormField style={this.getFieldStyle('mailingAddress')}control={TextArea} label='Mailing Address' name='mailingAddress' value={mailingAddress} placeholder='Address' onChange={this.handleChangeField}/>
                    </FormGroup>
                    <FormGroup widths={"equal"}>
                        <FormField style={this.getFieldStyle('phone1')} control={Input} label='Phone (Primary)' name='phone1' value={phone1} placeholder='Phone Number' onChange={this.handleChangeField}/>
                        <FormField style={this.getFieldStyle('phone2')} control={Input} label='Phone (Secondary)' name='phone2' value={phone2} placeholder='Phone Number' onChange={this.handleChangeField}/>
                        <FormField style={this.getFieldStyle('fax')} control={Input} label='Fax' name='fax' value={fax} placeholder='Fax Number' onChange={this.handleChangeField}/>
                    </FormGroup>
                    <FormField style={this.getFieldStyle('remarksContact')} control={TextArea} rows='2' label='Remarks' name='remarksContact' value={remarksContact} placeholder='' onChange={this.handleChangeField}/>
                    <FormField style={this.getFieldStyle('othersContact')} control={Input} label='Others' name='othersContact' value={othersContact} placeholder='' onChange={this.handleChangeField}/>
                </Form>
                <br /><br />
                <Divider horizontal>
                    <Header as='h4'>
                        <Icon name='users' />
                        Details of Authorised Person(s)
                    </Header>
                </Divider>
                <h3>Authorised Person 1</h3>
                <Form>
                    <FormGroup widths={"equal"}>
                        <FormField style={this.getFieldStyle('auth1Name')} control={Input} label='Name' name='auth1Name' value={auth1Name} placeholder='Name of Authorised Person 1' onChange={this.handleChangeField}/>
                        <FormField style={this.getFieldStyle('auth1Position')} control={Input} label='Position' name='auth1Position' value={auth1Position} placeholder='Position of Authorised Person 1' onChange={this.handleChangeField}/>
                        <FormField style={this.getFieldStyle('auth1Mobile')} control={Input} label='Mobile' name='auth1Mobile' value={auth1Mobile} placeholder='Mobile Number of Authorised Person 1' onChange={this.handleChangeField}/>
                        <FormField style={this.getFieldStyle('auth1Email')} control={Input} label='Email' name='auth1Email' value={auth1Email} placeholder='Email Address of Authorised Person 1' onChange={this.handleChangeField}/>
                    </FormGroup>
                    <FormGroup style={this.getFieldStyle('auth1OptOutEmail')} inline>
                        <label>Opt-out Email:</label>
                        <FormField control={Radio} label='Yes' value='yes' checked={auth1OptOutEmail === 'yes'} onChange={this.handleChangeOptOutEmail1}/>
                        <FormField control={Radio} label='No' value='no' checked={auth1OptOutEmail === 'no'} onChange={this.handleChangeOptOutEmail1}/>
                    </FormGroup>
                    <FormField style={this.getFieldStyle('auth1Remarks')} control={TextArea} rows='2' label='Remarks' name='auth1Remarks' value={auth1Remarks} placeholder='' onChange={this.handleChangeField}/>
                    <FormField style={this.getFieldStyle('auth1Others')} control={Input} label='Others' name='auth1Others' value={auth1Others} placeholder='' onChange={this.handleChangeField}/>
                </Form>
                <br />
                <h3>Authorised Person 2</h3>
                <Form>
                    <FormGroup widths={"equal"}>
                        <FormField style={this.getFieldStyle('auth2Name')} control={Input} label='Name' name='auth2Name' value={auth2Name} placeholder='Name of Authorised Person 2' onChange={this.handleChangeField}/>
                        <FormField style={this.getFieldStyle('auth2Position')} control={Input} label='Position' name='auth2Position' value={auth2Position} placeholder='Position of Authorised Person 2' onChange={this.handleChangeField}/>
                        <FormField style={this.getFieldStyle('auth2Mobile')} control={Input} label='Mobile' name='auth2Mobile' value={auth2Mobile} placeholder='Mobile Number of Authorised Person 2' onChange={this.handleChangeField}/>
                        <FormField style={this.getFieldStyle('auth2Email')} control={Input} label='Email' name='auth2Email' value={auth2Email} placeholder='Email Address of Authorised Person 2' onChange={this.handleChangeField}/>
                    </FormGroup>
                    <FormGroup style={this.getFieldStyle('auth2OptOutEmail')} inline>
                        <label>Opt-out Email:</label>
                        <FormField control={Radio} label='Yes' value='yes' checked={auth2OptOutEmail === 'yes'} onChange={this.handleChangeOptOutEmail2}/>
                        <FormField control={Radio} label='No' value='no' checked={auth2OptOutEmail === 'no'} onChange={this.handleChangeOptOutEmail2}/>
                    </FormGroup>
                    <FormField style={this.getFieldStyle('auth2Remarks')} control={TextArea} rows='2' label='Remarks' name='auth2Remarks' value={auth2Remarks} placeholder='' onChange={this.handleChangeField}/>
                    <FormField style={this.getFieldStyle('auth2Others')} control={Input} label='Others' name='auth2Others' value={auth2Others} placeholder='' onChange={this.handleChangeField}/>
                </Form>
                <br /><br />
                <h3>Authorised Person 3</h3>
                <Form>
                    <FormGroup widths={"equal"}>
                        <FormField style={this.getFieldStyle('auth3Name')} control={Input} label='Name' name='auth3Name' value={auth3Name} placeholder='Name of Authorised Person 3' onChange={this.handleChangeField}/>
                        <FormField style={this.getFieldStyle('auth3Position')} control={Input} label='Position' name='auth3Position' value={auth3Position} placeholder='Position of Authorised Person 3' onChange={this.handleChangeField}/>
                        <FormField style={this.getFieldStyle('auth3Mobile')} control={Input} label='Mobile' name='auth3Mobile' value={auth3Mobile} placeholder='Mobile Number of Authorised Person 3' onChange={this.handleChangeField}/>
                        <FormField style={this.getFieldStyle('auth3Email')} control={Input} label='Email' name='auth3Email' value={auth3Email} placeholder='Email Address of Authorised Person 3' onChange={this.handleChangeField}/>
                    </FormGroup>
                    <FormGroup style={this.getFieldStyle('auth3OptOutEmail')} inline>
                        <label>Opt-out Email:</label>
                        <FormField control={Radio} label='Yes' value='yes' checked={auth3OptOutEmail === 'yes'} onChange={this.handleChangeOptOutEmail3}/>
                        <FormField control={Radio} label='No' value='no' checked={auth3OptOutEmail === 'no'} onChange={this.handleChangeOptOutEmail3}/>
                    </FormGroup>
                    <FormField style={this.getFieldStyle('auth3Remarks')} control={TextArea} rows='2' label='Remarks' name='auth3Remarks' value={auth3Remarks} placeholder='' onChange={this.handleChangeField}/>
                    <FormField style={this.getFieldStyle('auth3Others')} control={Input} label='Others' name='auth3Others' value={auth3Others} placeholder='' onChange={this.handleChangeField}/>
                </Form>
                <br />
                <h3>Authorised Person 4</h3>
                <Form>
                    <FormGroup widths={"equal"}>
                        <FormField style={this.getFieldStyle('auth4Name')}control={Input} label='Name' name='auth4Name' value={auth4Name} placeholder='Name of Authorised Person 4' onChange={this.handleChangeField}/>
                        <FormField style={this.getFieldStyle('auth4Position')} control={Input} label='Position' name='auth4Position' value={auth4Position} placeholder='Position of Authorised Person 4' onChange={this.handleChangeField}/>
                        <FormField style={this.getFieldStyle('auth4Mobile')} control={Input} label='Mobile' name='auth4Mobile' value={auth4Mobile} placeholder='Mobile Number of Authorised Person 4' onChange={this.handleChangeField}/>
                        <FormField style={this.getFieldStyle('auth4Email')} control={Input} label='Email' name='auth4Email' value={auth4Email} placeholder='Email Address of Authorised Person 4' onChange={this.handleChangeField}/>
                    </FormGroup>
                    <FormGroup style={this.getFieldStyle('auth4OptOutEmail')} inline>
                        <label>Opt-out Email:</label>
                        <FormField control={Radio} label='Yes' value='yes' checked={auth4OptOutEmail === 'yes'} onChange={this.handleChangeOptOutEmail4}/>
                        <FormField control={Radio} label='No' value='no' checked={auth4OptOutEmail === 'no'} onChange={this.handleChangeOptOutEmail4}/>
                    </FormGroup>
                    <FormField style={this.getFieldStyle('auth4Remarks')} control={TextArea} rows='2' label='Remarks' name='auth4Remarks' value={auth4Remarks} placeholder='' onChange={this.handleChangeField}/>
                    <FormField style={this.getFieldStyle('auth4Others')} control={Input} label='Others' name='auth4Others' value={auth4Others} placeholder='' onChange={this.handleChangeField}/>
                </Form>
            </Container>
        )
    }
}

function getWritePermissionForField(fieldName, permissions) {
    var key = ""
    for (let field of fields) {
        if (field.field === fieldName && field.departmentName === departmentName) {
            key = field.key
        }
    }
    for (let permission of permissions) {
        if (permission.key === key) {
            if (permission.write === false) {
            }
            return permission.write
        }
    }
}

function getReadPermissionForField(fieldName, permissions) {
    var key = ""
    for (let field of fields) {
        if (field.field === fieldName && field.departmentName === departmentName) {
            key = field.key
        }
    }
    for (let permission of permissions) {
        if (permission.key === key) {
            return permission.read
        }
    }
}

export default ClientInfo